import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";

export const HelpLink = ({
  articleId,
  locale = "en-us",
  className,
}: {
  articleId: string;
  locale?: string;
  className?: string;
}) => {
  const classes = twMerge(
    "opacity-50 transition-opacity hover:opacity-100",
    className,
  );
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://support.meso.network/hc/${locale}/articles/${articleId}`}
      className={classes}
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </a>
  );
};
