import {
  SardineConfigAdditional,
  SardineUpdateConfig,
} from "@sardine-ai/react-js-wrapper";

/**
 * --------------------------------------------
 * Types extracted from meso-js. The long-term strategy is to source `meso-js` types from this repo
 * instead of the other way around. To start, we are using only specific types before taking on a formal refactor.
 * --------------------------------------------
 */

export enum Environment {
  /** For office use only. */
  LOCAL = "LOCAL",
  /** For office use only. */
  LOCAL_PROXY = "LOCAL_PROXY",
  /** For office use only. */
  DEV = "DEV",
  /** For office use only. */
  PREVIEW = "PREVIEW",
  /** In this environment, no crypto assets are transferred and no fiat assets are moved. */
  SANDBOX = "SANDBOX",
  /** In this environment, production networks will be used to transfer real crypto assets. Fiat assets are moved. */
  PRODUCTION = "PRODUCTION",
}

export enum TransferStatus {
  /** The transfer has been approved and is pending completion.
   *
   * At this point, funds have _not_ yet been moved.
   */
  APPROVED = "APPROVED",
  /** The transfer is complete and the user's funds are available. */
  COMPLETE = "COMPLETE",
  /** The transfer has failed. */
  DECLINED = "DECLINED",
  /** The transfer is in flight. */
  EXECUTING = "EXECUTING",
  UNKNOWN = "UNKNOWN",
}

/**
 * A fiat-to-crypto transfer.
 */
export type Transfer = {
  /**
   * The unique identifier for the Meso transfer. This can be used to look-up the status of the transfer or present UI to a user upon completion.
   */
  id: string;
  status: TransferStatus;
  updatedAt: string;
  /**
   * The on-chain identifier for the transfer.
   *
   * **Note:** This will only be available for transfers that are `COMPLETE`.
   */
  networkTransactionId?: string;
};

/**
 * An error surfaced from the `meso-js` integration.
 */
export type MesoError = {
  /** A client-friendly error message. */
  message: string;
};

export type TransferApprovedPayload = {
  transfer: Transfer & { status: TransferStatus.APPROVED };
};
export type TransferCompletePayload = {
  transfer: Transfer & { status: TransferStatus.COMPLETE };
};
export type ErrorPayload = { error: MesoError };
export type ConfigurationErrorPayload = { error: MesoError };
export type UnsupportedNetworkErrorPayload = { error: MesoError };
export type UnsupportedAssetErrorPayload = { error: MesoError };

export enum EventKind {
  /** An error has occurred while the Meso experience was active.  */
  ERROR = "ERROR",
  /** An error has occurred while initializing the Meso experience. */
  CONFIGURATION_ERROR = "CONFIGURATION_ERROR",
  /** The `network` provided in the configuration is not supported. */
  UNSUPPORTED_NETWORK_ERROR = "UNSUPPORTED_NETWORK_ERROR",
  /** The `destinationAsset` provided in the configuration is not supported. */
  UNSUPPORTED_ASSET_ERROR = "UNSUPPORTED_ASSET_ERROR",
  /** The user manually exited the Meso experience. */
  CLOSE = "CLOSE",
  /** A Meso transfer has been approved */
  TRANSFER_APPROVED = "TRANSFER_APPROVED",
  /** A Meso transfer has completed */
  TRANSFER_COMPLETE = "TRANSFER_COMPLETE",
  /** The iframe/window is ready and can be interacted with. */
  READY = "READY",
}

/**
 * All available events that will be surfaced via the `onEvent` callback.
 */
export type MesoEvent =
  | { kind: EventKind.TRANSFER_APPROVED; payload: TransferApprovedPayload }
  | { kind: EventKind.TRANSFER_COMPLETE; payload: TransferCompletePayload }
  | { kind: EventKind.ERROR; payload: ErrorPayload }
  | { kind: EventKind.CONFIGURATION_ERROR; payload: ConfigurationErrorPayload }
  | {
      kind: EventKind.UNSUPPORTED_NETWORK_ERROR;
      payload: UnsupportedNetworkErrorPayload;
    }
  | {
      kind: EventKind.UNSUPPORTED_ASSET_ERROR;
      payload: UnsupportedAssetErrorPayload;
    }
  | { kind: EventKind.CLOSE; payload: null }
  | { kind: EventKind.READY; payload: null };

/**
 * The expected result from requesting the user to sign a message with their wallet.
 *
 * Returning `undefined` will indicate the user rejected or canceled the signature request and the Meso flow will be updated to re-prompt for another signature.
 */
export type SignedMessageResult = Readonly<string> | undefined;

/**
 * A [CAIP-2](https://chainagnostic.org/CAIPs/caip-2) network identifier.
 */
export enum Network {
  ETHEREUM_MAINNET = "eip155:1",
  SOLANA_MAINNET = "solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp",
  POLYGON_MAINNET = "eip155:137",
  OP_MAINNET = "eip155:10",
  ARBITRUM_MAINNET = "eip155:42161",
  BITCOIN_MAINNET = "bip122:000000000019d6689c085ae165831e93",
  BASE_MAINNET = "eip155:8453",
}

export enum Asset {
  // CryptoAsset
  ETH = "ETH",
  SOL = "SOL",
  USDC = "USDC",
  /** @deprecated Use `POL` instead. */
  MATIC = "MATIC",
  POL = "POL",
  BTC = "BTC",

  // FiatAsset
  USD = "USD",
  EUR = "EUR",
}

export const CryptoAsset = {
  [Asset.ETH]: Asset.ETH,
  [Asset.SOL]: Asset.SOL,
  [Asset.USDC]: Asset.USDC,
  /** @deprecated Use `Asset.POL` instead. */
  [Asset.MATIC]: Asset.MATIC,
  [Asset.POL]: Asset.POL,
  [Asset.BTC]: Asset.BTC,
} as const;

export const FiatAsset = {
  [Asset.USD]: Asset.USD,
} as const;

/**
 * A stringified number representing an amount of USD.
 *
 * This value can optionally contain decimals and minor units if needed.
 *
 * Examples: `"10",`"0.01"`, `"1.2"`, `"100.23"`, `"1250"`, `"1250.40"`
 */
export type AssetAmount = `${number}${"." | ""}${number | ""}`;

/**
 * Screen position to launch the Meso experience.
 */
export enum Position {
  TOP_RIGHT = "top-right",
  BOTTOM_RIGHT = "bottom-right",
  BOTTOM_LEFT = "bottom-left",
  TOP_LEFT = "top-left",
  CENTER = "center",
}

/**
 * A stringified positive integer (excluding units) representing a number of pixels.
 */
export type PixelValue = `${number}`;

/**
 * Configuration to customize how the Meso experience is launched and presented.
 */
export type Layout = {
  /**
   * The position to launch the Meso experience. Defaults to `Position.TOP_RIGHT`.
   */
  position?: Position;
  /**
   * The number of pixels from the edges of the viewport to position the Meso experience.
   *
   * Offset values are additive. By default, Meso adds it's own `16px` offset.
   *
   * Providing a single value (e.g. `"10"`) will apply the offset to both the `x` and `y` axes.
   *
   * If an object specifying the `horizontal` or `vertical` values is provided, the offset will be applied to each axis respectively.
   * Omitting a value in this object will default to `"0"`.
   *
   * **Examples:**
   *
   * - `"10"` – Will apply a 10px offset to the Meso experience.
   * - `{ horizontal: "10" }` – Will apply a 10px offset horizontally (relative to the assigned `Position`) and 0 to the vertical offset.
   * - `{ vertical: "10" }` – Will apply a 10px offset vertically (relative to the assigned `Position`) and 0 to the horizontal offset.
   * - `{ horizontal: "10", vertical: "25" }` – Will apply a 10px offset 25px offset to the horizontal and vertical axes respectively (relative to the assigned `Position`).
   *
   * If no offset is provided, this value defaults to `"0"`.
   */
  offset?:
    | PixelValue
    | {
        /**
         * A value representing the horizontal (x-axis) offset relative to the assigned `Position`.
         */
        horizontal?: PixelValue;
        /**
         * A value representing the vertical (y-axis) offset relative to the assigned `Position`.
         */
        vertical: PixelValue;
      }
    | {
        /**
         * A value representing the horizontal (x-axis) offset relative to the assigned `Position`.
         */
        horizontal: PixelValue;
        /**
         * A value representing the vertical (y-axis) offset relative to the assigned `Position`.
         */
        vertical?: PixelValue;
      };
};

/**
 * Shared parameters to initialize the Meso experience.
 */
export type BaseConfiguration = Readonly<{
  /**
   * The Meso environment to use. (`Environment.SANDBOX` | `Environment.PRODUCTION`).
   */
  environment: Environment;
  /**
   * Unique ID for your partner account.
   */
  partnerId: string;
  /**
   * The network to be used for the transfer.
   */
  network: Network;
  /** The wallet address for the user. This address must be compatible with the selected `network` and `destinationAsset`. */
  walletAddress: string;
  /**
   * A stringified number including decimals (if needed) representing the source amount to be used for the transfer.
   *
   * Examples: `"10",`"0.01"`, `"1.2"`, `"100.23"`, `"1250", `"1250.40"`.
   */
  sourceAmount?: AssetAmount;
  /**
   * A stringified number including decimals (if needed) representing the destination amount desired from the transfer. If both `sourceAmount` and `destinationAmount` are specified, `destinationAmount` will take precedence.
   *
   * Examples: `"10",`"0.01"`, `"1.2"`, `"100.23"`, `"1250", `"1250.40"`.
   */
  destinationAmount?: AssetAmount;
  /**
   * Configuration to customize how the Meso experience is launched and presented.
   */
  layout?: Layout;
  /**
   * Determines the authentication mechanism for users to perform a transfer.
   *
   * In all scenarios, the user will still be required to perform two-factor authentication (2FA) and, in some cases provide email/password.
   *
   * If omitted, this will default to {@link AuthenticationStrategy.WALLET_VERIFICATION|WALLET_VERIFICATION}.
   */
  authenticationStrategy?: AuthenticationStrategy;
  /**
   * A handler to notify you when a message needs to be signed.
   */
  onSignMessageRequest: (message: string) => Promise<SignedMessageResult>;
  /**
   * A handler to notify you when an event has occurred.
   */
  onEvent: (event: MesoEvent) => void;
}>;

export type CashInConfiguration = BaseConfiguration & {
  /**
   * The fiat asset to be used. Defaults to `Asset.USD`.
   */
  sourceAsset?: keyof typeof FiatAsset;
  /**
   * The crypto asset to be transferred.
   */
  destinationAsset: keyof typeof CryptoAsset;
};

export type CashOutConfiguration = BaseConfiguration & {
  /**
   * The crypto asset to be transferred.
   */
  sourceAsset: keyof typeof CryptoAsset;
  /**
   * The fiat asset to be cashed out.
   */
  destinationAsset: keyof typeof FiatAsset;
  /**
   * A handler to notify you when a transaction needs to be sent.
   *
   * @param amount - quantity of the cryptocurrency to send.
   * @param recipientAddress - wallet address of the transaction recipient.
   * @param tokenAddress - contract address of the token being send.
   * @param decimals - number of decimal places used for the token.
   */
  onSendTransactionRequest: (
    amount: string,
    recipientAddress: string,
    tokenAddress: string,
    decimals: number,
  ) => Promise<void>;
};

export type TransferConfiguration = CashInConfiguration | CashOutConfiguration;

/**
 * Used to determine the type of authentication the user will need to perform for a transfer.
 */
export enum AuthenticationStrategy {
  /** Verify wallet by signing a message.
   *
   * New users and returning users with new wallets will still need to perform 2FA and login with email/password.
   **/
  WALLET_VERIFICATION = "wallet_verification",
  /** Verify a wallet by signing a message in the background _without_ prompting the user. This is useful for scenarios such as embedded wallets.
   *
   * New users and returning users with new wallets will still need to perform login and 2FA.
   */
  HEADLESS_WALLET_VERIFICATION = "headless_wallet_verification",
  /** Bypass wallet signing altogether and rely only on email/password and 2FA.
   *
   * This is useful for cases where pre-deployment smart contract wallets are being used and wallet verification cannot be performed.
   */
  BYPASS_WALLET_VERIFICATION = "bypass_wallet_verification",
}

/**
 * Utility type allowing optional keys from Record
 */
type OptionalStringify<T> = { [K in keyof T]?: string };

/**
 * Configuration that will be serialized to query params for the Transfer App.
 */
export type TransferIframeParams = Pick<
  TransferConfiguration,
  | "partnerId"
  | "network"
  | "walletAddress"
  | "sourceAsset"
  | "destinationAsset"
  | "authenticationStrategy"
> & {
  sourceAmount?: AssetAmount;
  destinationAmount?: AssetAmount;
  layoutPosition: NonNullable<Layout["position"]>;
  layoutOffset: NonNullable<Layout["offset"]>;
  /** The version of meso-js. */
  version: string;
  /** The mode for the rendering context of the Meso experience. */
  mode: IntegrationMode;
};

/**
 * The serialized configuration sent to the Transfer App as a query string.
 */
export type SerializedTransferIframeParams =
  OptionalStringify<TransferIframeParams>;

/**
 * The mode for the rendering context of the Meso experience.
 */
export enum IntegrationMode {
  /**
   * Intended to run inside an iframe in a web browser.
   */
  EMBEDDED = "embedded",
  /**
   * Intended to run inside a webview in a native mobile app.
   */
  WEBVIEW = "webview",
}

/**
 * Kind of messages sent between the Meso experience and parent window.
 */
export enum MessageKind {
  /**
   * Request from Meso experience to parent window to initiate signing.
   */
  REQUEST_SIGNED_MESSAGE = "REQUEST_SIGNED_MESSAGE",
  /**
   * Dispatch the result of a signature request from the parent window to the Meso experience.
   */
  RETURN_SIGNED_MESSAGE_RESULT = "RETURN_SIGNED_MESSAGE_RESULT",
  /**
   * Request from Meso experience to parent window to initiate sending of transaction.
   */
  REQUEST_SEND_TRANSACTION = "REQUEST_SEND_TRANSACTION",
  /**
   * Dispatch a message from the Meso experience to the parent window to close the experience.
   */
  CLOSE = "CLOSE",
  /**
   * Dispatch a message from the Meso experience to the parent window when the transfer has been updated.
   */
  TRANSFER_UPDATE = "TRANSFER_UPDATE",
  /**
   * Dispatch an error message from the Meso experience to the parent window.
   */
  ERROR = "ERROR",
  /**
   * Dispatch a configuration error when the Meso experience cannot be initialized.
   */
  CONFIGURATION_ERROR = "CONFIGURATION_ERROR",
  /**
   * Dispatch an unsupported network error when the `network` passed to initialize the Meso experience is not supported.
   */
  UNSUPPORTED_NETWORK_ERROR = "UNSUPPORTED_NETWORK_ERROR",
  /**
   * Dispatch an unsupported asset error when the `destinationAsset` passed to initialize the Meso experience is not supported.
   */
  UNSUPPORTED_ASSET_ERROR = "UNSUPPORTED_ASSET_ERROR",
  /**
   * Dispatch that the iframe is ready.
   */
  READY = "READY",

  /**
   * Dispatch to a calling window that the user needs to onboard. The calling window will handle presenting the onboarding flow.
   *
   * @deprecated This will be removed in a future release.
   */
  INITIATE_MODAL_ONBOARDING = "INITIATE_MODAL_ONBOARDING",

  /**
   * Dispatch an event to that the onboarding modal frame should be closed.
   *
   * @deprecated This will be removed in a future release.
   */
  RESUME_INLINE_FRAME = "RESUME_INLINE_FRAME",
}

export type RequestSignedMessagePayload = {
  /**
   * An opaque message to be signed via an action in the parent window.
   */
  messageToSign: string;
};

export type ReturnSignedMessagePayload = {
  /**
   * Signed message from parent window to Meso experience for blockchain address verification.
   *
   * This value will be omitted if the user cancels or rejects the wallet signature request.
   */
  signedMessage?: string;
};

export type RequestSendTransactionPayload = {
  /*
   * A stringified number including decimal (if needed) representing the
   * quantity to send for the transaction (e.g. `"10",`"0.01"`, `"1.2"`,
   * `"100.23"`, `"1250", `"1250.40"`).
   */
  amount: AssetAmount;
  /*
   * Wallet address of the transaction recipient (i.e. the Meso Deposit Address for Cash-Ins).
   */
  recipientAddress: string;
  /*
   * Contract address of the token being send (e.g.
   * "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48" for USDC on Ethereum).
   */
  tokenAddress: string;
  /*
   * Number of decimal places used for the token (e.g. 6 for USDC on Ethereum).
   */
  decimals: number;
};

/** @deprecated This will be removed in a future release. */
export enum ResumeInlineFrameAction {
  ONBOARDING_COMPLETE = "ONBOARDING_COMPLETE",
  ONBOARDING_CANCELED = "ONBOARDING_CANCELED",
  /** Onboarding has been terminated due to a user hitting Manual Review */
  ONBOARDING_TERMINATED = "ONBOARDING_TERMINATED",
  /** The user is attempting to login instead of signing up. */
  LOGIN_FROM_ONBOARDING = "LOGIN_FROM_ONBOARDING",
}

/** @deprecated This will be removed in a future release. */
export type ResumeInlineFramePayload = {
  /**
   * The action that triggered the onboarding modal to close. This value is used to determine which view to render in the inline frame.
   */
  action: ResumeInlineFrameAction;
};

/** A valid query param string with the leading `?` */
export type QueryString = `?${string}`;

/**
 * Structured `window.postMessage` messages between the Meso experience to parent window
 */
export type Message =
  | {
      kind: MessageKind.REQUEST_SIGNED_MESSAGE;
      payload: RequestSignedMessagePayload;
    }
  | {
      kind: MessageKind.RETURN_SIGNED_MESSAGE_RESULT;
      payload: ReturnSignedMessagePayload;
    }
  | {
      kind: MessageKind.REQUEST_SEND_TRANSACTION;
      payload: RequestSendTransactionPayload;
    }
  | {
      kind: MessageKind.CLOSE | MessageKind.READY;
    }
  | {
      kind: MessageKind.INITIATE_MODAL_ONBOARDING;
      payload: {
        /**
         * The qualified pathname (including leading `/`) in Onboarding that the user will land on once the modal is opened.         *
         */
        initialPathname: string;

        /** If provided, a valid query param string with the leading `?`*/
        search?: QueryString;
      };
    }
  | {
      kind: MessageKind.RESUME_INLINE_FRAME;
      payload: ResumeInlineFramePayload;
    }
  | {
      kind: MessageKind.TRANSFER_UPDATE;
      payload: Pick<Transfer, "id" | "status" | "updatedAt"> &
        Partial<Pick<Transfer, "networkTransactionId">>;
    }
  | { kind: MessageKind.ERROR; payload: MesoError }
  | { kind: MessageKind.CONFIGURATION_ERROR; payload: MesoError }
  | { kind: MessageKind.UNSUPPORTED_NETWORK_ERROR; payload: MesoError }
  | { kind: MessageKind.UNSUPPORTED_ASSET_ERROR; payload: MesoError };

export type PostMessageHandlerFn = (
  message: Message,
  /**
   * The `reply` callback can be used to emit a message back to the origin that sent the original message.
   */
  reply: (message: Message) => void,
) => void;

/**
 * The return type from creating a "bus". This can be used to attach/detach from events and send messages.
 */
export type PostMessageBus = {
  /**
   * Subscribe to an event using the message kind (name).
   *
   * The attached handler will be invoked each time this event is seen until the event handler is detached.
   */
  on: (eventKind: MessageKind, handler: PostMessageHandlerFn) => PostMessageBus;

  /**
   * Subscribe to an event using the message kind (name).
   *
   * The attached handler will be invoked only the first time this event is seen.
   */
  once: (
    eventKind: MessageKind,
    handler: PostMessageHandlerFn,
  ) => PostMessageBus;

  /**
   * Send a message to a specific [origin](https://developer.mozilla.org/en-US/docs/Web/API/Location/origin). If the `targetOrigin` is omitted, the message will be broadcast to all origins (`*`).
   */
  emit: (message: Message, targetOrigin?: string) => PostMessageBus;

  /**
   * Detach event handlers and end post message communications.
   */
  destroy: () => void;
};

/**
 * A handler function for a message event.
 */
export type HandlerFn = Parameters<PostMessageBus["on"]>[1];

/**
 * A structured error returned when the post message bus cannot be initialized.
 */
export type PostMessageBusInitializationError = {
  /**
   * A _developer_ friendly message containing details of the error.
   */
  message: string;
};

/** ---------------------------------
 * End types extracted from meso-js.
 * ---------------------------------- */

export enum NetworkNamespace {
  FIAT = "fiat",
  EIP55 = "eip155",
  SOLANA = "solana",
  BTC = "btc",
}

/**
 * A [CAIP-19 Asset identifier](https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-19.md).
 */
export enum CAIPAsset {
  FIAT_USD = "fiat:x/iso4217:USD",
  FIAT_EUR = "fiat:x/iso4217:EUR",
  ETHEREUM_MAINNET_USDC = "eip155:1/erc20:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  ETHEREUM_MAINNET_ETH = "eip155:1/slip44:60",
  SOLANA_MAINNET_USDC = "solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp/spl:EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
  SOLANA_MAINNET_SOL = "solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp/slip44:501",
  ARBITRUM_ONE_USDC = "eip155:42161/erc20:0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
  ARBITRUM_ONE_ETH = "eip155:42161/slip44:60",
  OP_MAINNET_USDC = "eip155:10/erc20:0x0b2c639c533813f4aa9d7837caf62653d097ff85",
  OP_MAINNET_ETH = "eip155:10/slip44:60",
  POLYGON_MAINNET_USDC = "eip155:137/erc20:0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
  POLYGON_MAINNET_MATIC = "eip155:137/slip44:966",
  BITCOIN_MAINNET_BTC = "bip122:000000000019d6689c085ae165831e93/slip44:0",
  BASE_MAINNET_ETH = "eip155:8453/slip44:60",
  BASE_MAINNET_USDC = "eip155:8453/erc20:0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
  // Testnet Assets
  ETHEREUM_SEPOLIA_USDC = "eip155:11155111/erc20:0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
  ETHEREUM_SEPOLIA_ETH = "eip155:11155111/slip44:60",
  SOLANA_DEVNET_USDC = "solana:EtWTRABZaYq6iMfeYKouRu166VU2xqa1/spl:4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU",
  SOLANA_DEVNET_SOL = "solana:EtWTRABZaYq6iMfeYKouRu166VU2xqa1/slip44:501",
  ARBITRUM_SEPOLIA_ETH = "eip155:421614/slip44:60",
  ARBITRUM_SEPOLIA_USDC = "eip155:421614/erc20:0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d",
  OP_SEPOLIA_ETH = "eip155:11155420/slip44:60",
  OP_SEPOLIA_USDC = "eip155:11155420/erc20:0x5fd84259d66Cd46123540766Be93DFE6D43130D7",
  POLYGON_AMOY_MATIC = "eip155:80002/slip44:966",
  POLYGON_AMOY_USDC = "eip155:80002/erc20:0x41e94eb019c0762f9bfcf9fb1e58725bfb0e7582",
  BITCOIN_TESTNET_BTC = "bip122:000000000933ea01ad0ee984209779ba/slip44:0",
  BASE_SEPOLIA_ETH = "eip155:84532/slip44:60",
  BASE_SEPOLIA_USDC = "eip155:84532/erc20:0x036CbD53842c5426634e7929541eC2318f3dCF7e",
}

/**
 * A [CAIP-2 Network identifier](https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-2.md).
 */
export enum CAIPNetwork {
  ETHEREUM_MAINNET = "eip155:1",
  SOLANA_MAINNET = "solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp",
  ARBITRUM_ONE = "eip155:42161",
  BASE_MAINNET = "eip155:8453",
  OP_MAINNET = "eip155:10",
  POLYGON_MAINNET = "eip155:137",
  BITCOIN_MAINNET = "bip122:000000000019d6689c085ae165831e93",
  // Testnets
  ETHEREUM_SEPOLIA = "eip155:11155111",
  SOLANA_DEVNET = "solana:EtWTRABZaYq6iMfeYKouRu166VU2xqa1",
  ARBITRUM_SEPOLIA = "eip155:421614",
  BASE_SEPOLIA = "eip155:84532",
  OP_SEPOLIA = "eip155:11155420",
  POLYGON_AMOY = "eip155:80002",
  BITCOIN_TESTNET = "bip122:000000000933ea01ad0ee984209779ba",
}

export type FormField<T = string> = {
  value: T;
  /**
   * Whether the field's value has been calculated to be valid.
   *
   * **Note:** The presentation of invalid states is dependent on `isDirty and `isTouched`.
   */
  isValid: boolean;
  /**
   * Whether the user has attempted any input in this field.
   */
  isDirty: boolean;
  /**
   * Whether the user has visited (focused and blurred) this field.
   */
  isTouched: boolean;
};

/**
 * A local mapping of expected responses from the API for `User.status`
 */
export enum UserStatus {
  ACTIVE = "active",
  NEW = "new",
  IN_REVIEW = "in_review",
  FROZEN = "frozen",
  PROVISIONAL = "provisional",
  UNKNOWN = "unknown",
  DECLINED = "declined",
  BLOCKED = "blocked",
  IN_REVIEW_BLOCKED = "in_review_blocked",
}

/** An internal-use identifier for user-facing Meso properties. This value is typically used for telemetry. */
export type MesoIntegrationProperty =
  | "transfer-app"
  | "onboarding"
  | "account"
  | "threeds";

export enum OnboardingAppRenderContext {
  /** The onboarding application is running as a React component embedded in the Transfer app. */
  EMBEDDED_IN_TRANSFER = "EMBEDDED_IN_TRANSFER",
  /** The onboarding application is running in it's own window and requires communication with the Transfer window. */
  BREAKOUT_FROM_TRANSFER = "BREAKOUT_FROM_TRANSFER",
}

/** An [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character country code for Meso-supported countries. */
export enum CountryCodeAlpha2 {
  US = "US",
  AT = "AT",
  BE = "BE",
  BG = "BG",
  HR = "HR",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  EE = "EE",
  FI = "FI",
  FR = "FR",
  DE = "DE",
  GR = "GR",
  HU = "HU",
  IS = "IS",
  IE = "IE",
  IT = "IT",
  LV = "LV",
  LT = "LT",
  LU = "LU",
  MT = "MT",
  NL = "NL",
  NO = "NO",
  PL = "PL",
  PT = "PT",
  RO = "RO",
  SK = "SK",
  SI = "SI",
  ES = "ES",
  SE = "SE",
}

/** An [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 3 character country code for Meso-supported countries. */
export enum CountryCodeAlpha3 {
  USA = "USA",
  AUT = "AUT",
  BEL = "BEL",
  BGR = "BGR",
  HRV = "HRV",
  CYP = "CYP",
  CZE = "CZE",
  DNK = "DNK",
  EST = "EST",
  FIN = "FIN",
  FRA = "FRA",
  DEU = "DEU",
  GRC = "GRC",
  HUN = "HUN",
  ISL = "ISL",
  IRL = "IRL",
  ITA = "ITA",
  LVA = "LVA",
  LTU = "LTU",
  LUX = "LUX",
  MLT = "MLT",
  NLD = "NLD",
  NOR = "NOR",
  POL = "POL",
  PRT = "PRT",
  ROU = "ROU",
  SVK = "SVK",
  SVN = "SVN",
  ESP = "ESP",
  SWE = "SWE",
}

export type NonUSCountryCodeAlpha3 = Exclude<
  CountryCodeAlpha3,
  CountryCodeAlpha3.USA
>;

/**
 * An [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character country code for Meso-supported issuing banks countries for EEA users
 */
export enum AllowedEEAIssuingBankCountryCodes {
  AT = "AT",
  BE = "BE",
  BG = "BG",
  HR = "HR",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  EE = "EE",
  FI = "FI",
  FR = "FR",
  DE = "DE",
  GR = "GR",
  HU = "HU",
  IS = "IS",
  IE = "IE",
  IT = "IT",
  // Add Liechtenstein
  LI = "LI",
  LV = "LV",
  LT = "LT",
  LU = "LU",
  MT = "MT",
  NL = "NL",
  NO = "NO",
  PL = "PL",
  PT = "PT",
  RO = "RO",
  SK = "SK",
  SI = "SI",
  ES = "ES",
  SE = "SE",
}

/**
 * A manual copy of Sardine's type definitions.
 * This is required due to react version incompatibility.
 */
export type ExtractedSardineContext = {
  setupSardineWithConfig: (config: SardineConfigAdditional) => void;
  updateSardineConfig: (config: SardineUpdateConfig) => void;
  getSardineSessionKey: (callback: (sessionKey: string) => void) => void;
  logoutUserOnSardine: (callback: (status: boolean) => void) => void;
};
