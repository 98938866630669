import { Button } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef } from "react";
import { FiatInstrument, SingleUseInstrument } from "../../generated/schema";
import { Text } from "../Text";
import { faCcApplePay } from "@fortawesome/free-brands-svg-icons";
import { motion } from "framer-motion";
import { useImpressionTracker } from "../../hooks/useImpressionTracker";
import { MesoKitContext } from "../../MesoKitContext";
import { TelemetryEvents } from "@tigris/common";

export const ApplePayTooltip = ({
  setFiatInstrument,
}: {
  setFiatInstrument: (instrument: FiatInstrument | SingleUseInstrument) => void;
}) => {
  const { posthog } = useContext(MesoKitContext);
  const { getImpressionCount, trackImpression } = useImpressionTracker();
  const impressionCount = useRef(getImpressionCount("applePayTooltip"));

  useEffect(() => {
    if (impressionCount.current === 0) {
      trackImpression("applePayTooltip");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return impressionCount.current >= 1 ? null : (
    <motion.div
      // This is scale in small from transfer-app animation.ts
      initial={{ opacity: 0, scale: 0.95, y: 8, zIndex: 50 }}
      animate={{
        opacity: 1,
        scale: 1,
        y: 0,
      }}
      transition={{ delay: 0.25 }}
      exit={{ opacity: 0, scale: 0.95, y: 8 }}
      data-testid="ApplePayTooltip"
      className="absolute top-9 z-50 w-[21rem] rounded-2xl border border-neutral-200 bg-white p-3 text-sm shadow-xl dark:border-neutral-700 dark:bg-neutral-800"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon
            data-testid="FiatInstrumentIcon"
            size="xl"
            icon={faCcApplePay}
          />
          <Text className="leading-tight font-medium">
            Now accepting Apple Pay!
          </Text>
        </div>
        <Button
          className="h-6 rounded-xl px-3 py-2 text-xs font-semibold"
          onClick={() => {
            posthog?.capture(TelemetryEvents.applePayTooltipClick);
            setFiatInstrument(SingleUseInstrument.APPLE_PAY);
          }}
        >
          Try it out
        </Button>
      </div>
      <div className="absolute top-[-1px] left-24 size-1.5 -translate-x-2/3 -translate-y-1/2 -rotate-45 transform border-t border-r border-neutral-200 bg-white dark:border-neutral-700 dark:bg-neutral-800"></div>
    </motion.div>
  );
};
