import { CardNumber, Cvv, ExpiryDate, PaymentMethod } from "frames-react";
import { PaymentCardIcon } from "./PaymentCardIcon";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const skeletonLineVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: [0.4, 0.8, 0.4],
    transition: {
      repeat: Infinity,
      repeatType: "loop",
      duration: 1.4,
      ease: "linear",
    },
  },
  exit: { opacity: 0 },
};

/**
 * Wrapper for CKO frame elements.
 */
export const PaymentCardInputFrame = ({
  isFocused,
  disabled,
  formIsReady,
  cardBrand,
}: {
  isFocused: boolean;
  disabled: boolean;
  formIsReady: boolean;
  cardBrand?: PaymentMethod;
}) => {
  const containerClassName = twMerge(
    "relative w-full rounded-none rounded-b-2xl border transition duration-150",
    "dark:border-transparent dark:border-t-neutral-600 dark:bg-neutral-700 dark:text-white",
    isFocused && "outline-hidden ring-2 ring-primary-light ring-inset",
  );
  const inputClassName = twMerge(
    "flex h-12 w-full flex-row items-center px-3 rounded-b-2xl",
    disabled && "input-disabled",
  );

  return (
    <div className={containerClassName}>
      <AnimatePresence>
        {!formIsReady && (
          <motion.div
            key="paymentCardInputFrameLoader"
            variants={skeletonLineVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="absolute top-0 left-0 h-full w-full rounded-b-2xl bg-neutral-100 opacity-20 select-none dark:bg-neutral-700"
          />
        )}
      </AnimatePresence>

      <div className={inputClassName}>
        <div className="cursor-default pr-1 text-neutral-400">
          <PaymentCardIcon brand={cardBrand} />
        </div>
        <CardNumber className="h-12 grow py-[3px]" tabIndex={1} />
        <div className="flex flex-row items-center justify-between">
          <ExpiryDate className="h-12 w-14 py-[3px]" tabIndex={2} />
          <Cvv className="h-12 w-9 py-[3px]" tabIndex={3} />
        </div>
      </div>
    </div>
  );
};
