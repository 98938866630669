type LogoSize = {
  /** Tailwind class names to provide to the logo wrapper. */
  wrapper: string;
  /** Tailwind class names to provide to the logo mark container. */
  logoMarkContainer: string;
  /** Tailwind class names to provide to the logo plate. */
  plate: string;
  /** Tailwind class names to provide to the logo text. */
  text: string;
};

export const LOGO_SIZES: Record<"xs" | "sm" | "md" | "lg" | "xl", LogoSize> = {
  xs: {
    wrapper: "gap-[0.125rem]",
    logoMarkContainer: "w-4 h-4 after:blur-[2px]",
    plate: "p-[0.085rem]",
    text: "text-xs",
  },
  sm: {
    wrapper: "gap-1",
    logoMarkContainer: "w-6 h-6 after:blur-[2px]",
    plate: "p-[0.125rem]",
    text: "text-sm",
  },
  md: {
    wrapper: "gap-[.3rem]",
    logoMarkContainer: "w-9 h-9 after:blur-[8px]",
    plate: "p-[0.14rem]",
    text: "text-md",
  },
  lg: {
    wrapper: "gap-[.4rem]",
    logoMarkContainer: "w-16 h-16 after:blur-sm",
    plate: "p-[0.2rem]",
    text: "text-lg",
  },
  xl: {
    wrapper: "gap-2",
    logoMarkContainer: "w-24 h-24 after:blur-md",
    plate: "p-1",
    text: "text-2xl",
  },
};
