import { Title, Text, Button, CopyToClipboard } from "@tigris/mesokit";
import { useAppContext } from "../hooks/useAppContext";
import { useCallback, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Dialog, DialogPanel, Popover, Transition } from "@headlessui/react";
import { faEllipsis, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resolveRemoveUserWallet, resolveUser } from "../api";
import { toast } from "sonner";

const confirmRemoveWalletDialogAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const TOAST_ID = "RemoveWallet";

export function WalletActionMenu({ walletAddress }: { walletAddress: string }) {
  const { updateUser } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const removeWalletResult = await resolveRemoveUserWallet({
      input: { walletAddress },
    });
    if (removeWalletResult.isErr()) {
      setIsLoading(false);
      toast.error(removeWalletResult.error, { id: TOAST_ID });
      return;
    }
    toast.dismiss(TOAST_ID);

    const userResult = await resolveUser();
    if (userResult.isErr()) {
      setIsLoading(false);
      toast.error(userResult.error, { id: TOAST_ID });
      return;
    }

    updateUser(userResult.value);
    setIsLoading(false);
    setIsOpen(false);
  }, [updateUser, walletAddress]);

  return (
    <>
      <Popover className="relative z-10">
        <Popover.Button className="flex h-8 w-8 items-center justify-center rounded-lg border border-neutral-200 md:h-6 md:w-6 dark:border-neutral-700">
          <FontAwesomeIcon className="h-4 w-4" icon={faEllipsis} />
        </Popover.Button>

        <Transition
          enter="transition duration-200 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-200 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel className="absolute right-10 w-max -translate-y-[calc(50%+16px)] rounded-lg border border-solid bg-white p-2 text-neutral-700 shadow-lg md:right-8 md:-translate-y-[calc(50%+12px)] md:p-3 dark:border-neutral-700 dark:bg-neutral-800 dark:text-white">
            <CopyToClipboard
              text={walletAddress}
              containerClassName="cursor-pointer rounded p-2 hover:bg-neutral-100 dark:hover:bg-neutral-700"
            >
              <span className="ml-[10px] text-sm font-semibold">
                Copy Address
              </span>
            </CopyToClipboard>
            <div
              className="text-danger hover:bg-danger/10 group flex cursor-pointer items-center rounded p-2 text-sm"
              onClick={() => setIsOpen(true)}
            >
              <FontAwesomeIcon icon={faTrash} className="mr-2 h-4 w-4" />
              <span className="font-semibold">Remove Wallet</span>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      {/* Dialog uses React.createPortal to inject into DOM */}
      <AnimatePresence>
        {isOpen && (
          <Dialog
            as={motion.div}
            className="relative z-10"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            {...confirmRemoveWalletDialogAnimation}
            static
          >
            <div
              className="fixed inset-0 bg-white/50 dark:bg-black/50"
              aria-hidden="true"
            />
            <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
              <DialogPanel
                data-testid="change-password-dialog"
                className="flex w-96 flex-col gap-4 rounded-xl bg-white p-8 text-black shadow-2xl md:rounded-3xl dark:bg-neutral-900"
              >
                <Title.Small className="font-bold">Remove Wallet</Title.Small>
                <Text>
                  {`Removing a wallet will disconnect it from Meso. Should you
                  wish to use this wallet with Meso in the future, you'll need
                  to add it back manually.`}
                </Text>
                <div className="flex flex-row items-center justify-between gap-1 md:gap-8">
                  <Button
                    data-testid="RemoveWallet:Cancel"
                    className="h-8 bg-transparent px-8 text-xs text-black dark:text-white"
                    onClick={() => setIsOpen(false)}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <div className="flex-grow">
                    <Button
                      data-testid="RemoveWallet:Confirmation"
                      className="bg-danger hover:bg-danger dark:hover:bg-danger h-8 px-4 text-sm font-bold text-white hover:opacity-100"
                      primary={false}
                      disabled={isLoading}
                      onClick={handleSubmit}
                    >
                      Remove Wallet
                    </Button>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </Dialog>
        )}
      </AnimatePresence>
    </>
  );
}
