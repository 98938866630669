import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { useAppContext } from "../hooks/useAppContext";

export const LogoutPill = () => {
  const { logout, session } = useAppContext();
  const theme = session?.user?.theme
    ? `theme-${session.user.theme}`
    : "theme-default";

  return (
    <div
      className={`flex h-fit w-fit items-center rounded-full bg-white text-sm font-bold text-neutral-700 shadow-lg dark:bg-neutral-700 dark:text-white ${theme}`}
    >
      <div className="wallet-pill rounded-full py-2 pr-3 pl-2 sm:block">
        <FontAwesomeIcon icon={faUserCircle} />
        <span className="pl-2">
          {session?.user?.email && session.user.email.length > 0
            ? session.user.email.split("@")[0]
            : "Early Adopter"}
        </span>
      </div>
      <button onClick={() => logout()} className="h-full pr-2 pl-1 opacity-50">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-6 w-6"
        >
          <path
            fillRule="evenodd"
            d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm5.03 4.72a.75.75 0 010 1.06l-1.72 1.72h10.94a.75.75 0 010 1.5H10.81l1.72 1.72a.75.75 0 11-1.06 1.06l-3-3a.75.75 0 010-1.06l3-3a.75.75 0 011.06 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};
