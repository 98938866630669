// Export all types from `common` into this types module
export * from "@tigris/common/src/types";
export type { InternationalLookupAddressDetail } from "./utils/smarty/types";

type Limit = {
  amount: number;
  currency: string;
};

/**
 * A domain model of user limits for the frontend.
 */
export type UserLimits = {
  /** The minimum value the user is allowed to edit down to. This is the Meso product minimum. */
  mesoMinimum: number;
  /** The maximum value the user is allowed to transfer in a 30-day rolling window. */
  monthlyMaximumAmount: Limit;
  /** The maximum value the user is allowed to edit up to. This is the user's current remaining 30-day limit amount. */
  monthlyAmountAvailable: Limit;
  /** The currently used amount of the user's current 30-day limit. */
  monthlyAmountUsed: Limit;
  /** The amount of the user's current 30-day limit that has been used expressed as a decimal.  */
  percentUsed: number;
  /** Whether the user is nearing their limit. */
  approachingLimit: boolean;
  /** Whether the user has used up their current 30-day limit. */
  limitReached: boolean;
  /** Whether the user's currently requested amount is greater than their current 30-day rolling limit. */
  requestedAmountExceedsLimit: boolean;
  /** Whether the user's currently requested amount can be edited. This will be true if they can edit down to an amount that is `>=` the `minimum`. */
  editable: boolean;
  /** The currency code of the user's residence country */
  currencyCode: string;
};

/** The error `codes` that may come back on a failed `addPaymentCard` mutation. */
export type AddPaymentCardErrorCode =
  | "invalid_card_information"
  | "unsupported_issuing_country"
  | "unsupported_card_type"
  | "user_blocked"
  | "card_cannot_be_processed"
  | "unknown";

export const isAddPaymentCardErrorCode = (
  value: string,
): value is AddPaymentCardErrorCode =>
  [
    "invalid_card_information",
    "unsupported_issuing_country",
    "unsupported_card_type",
    "user_blocked",
    "card_cannot_be_processed",
  ].includes(value as AddPaymentCardErrorCode);
