import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { AccountAppRoutes, PARTNER_ID } from "../utils/constants";
import { Session, User } from "../types";
import {
  resetSessionIdentifier,
  resolveUpdateDataConsent,
  resolveSession,
  setBearerToken,
} from "../api";
import { userFactory } from "../factories/User";
import { AppContext } from "./AppContext";
import { Posthog, Sentry, updateConsent } from "@tigris/common";
import { useCookieConsent } from "@tigris/mesokit";

export const AppContextProvider = ({ children }: PropsWithChildren) => {
  // React strict mode causes useEffect callbacks to be invoked twice. We use
  // the `sessionInitialized` ref to avoid setting a new session token from a
  // second NewSession invocation.
  const sessionInitialized = useRef(false);
  const [session, setSession] = useState<Session | undefined>(() => {
    if (import.meta.env.DEV && sessionStorage.getItem("autoLogin") === "true") {
      sessionStorage.setItem("autoLogin", "true");

      return {
        id: "stubs",
        token: "stub",
        riskSessionKey: "stub",
        user: userFactory.build(),
      };
    }

    return undefined;
  });
  const navigate = useNavigate();
  const onSavePreferences = useCallback(async (preferences: string) => {
    return await resolveUpdateDataConsent({
      input: { dataConsent: preferences },
    });
  }, []);
  const cookieConsent = useCookieConsent({
    property: "account",
    onSavePreferences,
  });

  const newSession = useCallback(
    () =>
      (async () => {
        const resolveSessionResult = await resolveSession({
          input: {
            partnerId: PARTNER_ID,
            dataConsent: JSON.stringify(cookieConsent.preferences),
          },
        });

        if (resolveSessionResult.isErr()) {
          return;
        }

        const newSessionResult = resolveSessionResult.value;
        const newSession = {
          id: newSessionResult.id,
          token: newSessionResult.token,
          riskSessionKey: newSessionResult.riskSession.sessionKey,
        };
        setBearerToken(newSession.token, navigate);

        Sentry.setContext("session", newSession);

        setSession(newSession);
      })(),
    [cookieConsent.preferences, navigate],
  );

  const logout = useCallback(
    (
      /** A valid query string (omitting the leading `?`) that will be appended to the root of the landing page. */
      loggedOutQueryParams?: string,
    ) => {
      setSession(undefined);
      resetSessionIdentifier();
      newSession();

      navigate(
        {
          pathname: AccountAppRoutes.ROOT,
          ...(loggedOutQueryParams
            ? { search: `?${loggedOutQueryParams}` }
            : {}),
        },
        { replace: true },
      );
    },
    [navigate, newSession],
  );

  const updateUser = useCallback((user: User) => {
    setSession((previousSession) => {
      if (previousSession) {
        return { ...previousSession, user };
      }
      Posthog.identify(user.id);
    });
  }, []);

  useEffect(() => {
    if (sessionInitialized.current) return;
    sessionInitialized.current = true;
    newSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => updateConsent(cookieConsent.preferences.performance),
    [cookieConsent.preferences.performance],
  );

  const contextValue = useMemo(
    () => ({ session, logout, updateUser, setSession, cookieConsent }),
    [cookieConsent, logout, session, updateUser, setSession],
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
