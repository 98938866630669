import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountAppRoutes } from "../../utils/constants";
import { internationalUserFactory, userFactory } from "../../factories/User";
import { twMerge } from "tailwind-merge";
import { AppContext } from "../../contexts/AppContext";

const AUTO_LOGIN_KEY = "autoLogin";

export const DevOnlyControls = () => {
  const navigate = useNavigate();
  const { setSession } = useContext(AppContext);
  const [autoLoginEnabled, setAutoLoginEnabled] = useState<boolean>(() => {
    const enabled = sessionStorage.getItem(AUTO_LOGIN_KEY);

    return enabled === "true";
  });
  const [isLightMode, setIsLightMode] = useState(
    () => !document.documentElement.classList.contains("dark-mode"),
  );

  const toggleAutoLogin = useCallback(
    (userVariant: "international" | "us") => () => {
      if (autoLoginEnabled) {
        sessionStorage.removeItem(AUTO_LOGIN_KEY);
        setAutoLoginEnabled(false);
        navigate(AccountAppRoutes.ROOT);
      } else {
        sessionStorage.setItem(AUTO_LOGIN_KEY, "true");
        setAutoLoginEnabled(true);
        setSession({
          id: "stub",
          token: "stub",
          riskSessionKey: "stub",
          user:
            userVariant === "us"
              ? userFactory.build()
              : internationalUserFactory.build(),
        });
        navigate(AccountAppRoutes.PROFILE_SETTINGS);
      }
    },
    [autoLoginEnabled, navigate, setSession],
  );

  useEffect(() => {
    if (isLightMode) {
      document.documentElement.classList.remove("dark-mode");
    } else {
      document.documentElement.classList.add("dark-mode");
    }
  }, [isLightMode]);

  // This statement prevents this entire module from being bundled in our production builds
  if (!(import.meta.env.VITE_TIGRIS_ENV === "preview" || import.meta.env.DEV)) {
    return;
  }

  return (
    <div className="fixed right-10 bottom-10 rounded-xl bg-black p-3 text-[#cacaca]">
      <div className="flex flex-col gap-2">
        <div className="flex w-full items-center justify-between gap-1 border-b border-b-neutral-700 pb-2 text-xs font-bold">
          <div className="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="size-4"
            >
              <path
                fillRule="evenodd"
                d="M11.5 8a3.5 3.5 0 0 0 3.362-4.476c-.094-.325-.497-.39-.736-.15L12.099 5.4a.48.48 0 0 1-.653.033 8.554 8.554 0 0 1-.879-.879.48.48 0 0 1 .033-.653l2.027-2.028c.24-.239.175-.642-.15-.736a3.502 3.502 0 0 0-4.476 3.427c.018.99-.133 2.093-.914 2.7l-5.31 4.13a2.015 2.015 0 1 0 2.828 2.827l4.13-5.309c.607-.78 1.71-.932 2.7-.914L11.5 8ZM3 13.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                clipRule="evenodd"
              />
            </svg>
            DevTools
          </div>

          <div
            className="cursor-pointer"
            onClick={() => {
              setIsLightMode(!isLightMode);
            }}
          >
            {isLightMode ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                fill="currentColor"
                className="size-4"
              >
                <path d="M190.6 66.8c-38.8 37.8-62.9 90.7-62.9 149.2c0 108.9 83.5 198.3 189.9 207.3C289.8 439 257.7 448 223.5 448C117.7 448 32 362.1 32 256c0-94.8 68.5-173.5 158.6-189.2zm66.1-21.5c-1.5-6.9-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="currentColor"
                className="size-4"
              >
                <path d="M256 0c8.8 0 16 7.2 16 16l0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80c0-8.8 7.2-16 16-16zM0 256c0-8.8 7.2-16 16-16l80 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-80 0c-8.8 0-16-7.2-16-16zm400 0c0-8.8 7.2-16 16-16l80 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-80 0c-8.8 0-16-7.2-16-16zM256 400c8.8 0 16 7.2 16 16l0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80c0-8.8 7.2-16 16-16zM75 75c6.2-6.2 16.4-6.2 22.6 0l56.6 56.6c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L75 97.6c-6.2-6.2-6.2-16.4 0-22.6zm0 362c-6.2-6.2-6.2-16.4 0-22.6l56.6-56.6c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L97.6 437c-6.2 6.2-16.4 6.2-22.6 0zM357.8 154.2c-6.2-6.2-6.2-16.4 0-22.6L414.4 75c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-56.6 56.6c-6.2 6.2-16.4 6.2-22.6 0zm0 203.6c6.2-6.2 16.4-6.2 22.6 0L437 414.4c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-56.6-56.6c-6.2-6.2-6.2-16.4 0-22.6zM336 256a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zm-192 0a112 112 0 1 1 224 0 112 112 0 1 1 -224 0z" />
              </svg>
            )}
          </div>
        </div>

        {autoLoginEnabled ? (
          <button
            className={twMerge(
              "rounded-ts-card transition-al px-4 py-2 text-sm",
              autoLoginEnabled && "bg-neutral-800",
              !autoLoginEnabled && "bg-neutral-800",
            )}
            onClick={toggleAutoLogin("us")}
          >
            <div className="flex items-center gap-2 align-middle">
              <div
                className={twMerge(
                  "h-[10px] w-[10px] rounded-full",
                  autoLoginEnabled ? "bg-green-500" : "bg-red-500",
                )}
              />
              {autoLoginEnabled ? "Disable Auto Login" : "Login (US user)"}
            </div>
          </button>
        ) : (
          <>
            <button
              className={twMerge(
                "rounded-ts-card transition-al px-4 py-2 text-sm",
                autoLoginEnabled && "bg-neutral-800",
                !autoLoginEnabled && "bg-neutral-800",
              )}
              onClick={toggleAutoLogin("us")}
            >
              <div className="flex items-center gap-2 align-middle">
                <div
                  className={twMerge(
                    "h-[10px] w-[10px] rounded-full",
                    autoLoginEnabled ? "bg-green-500" : "bg-red-500",
                  )}
                />
                {autoLoginEnabled ? "Disable Auto Login" : "Login (US user)"}
              </div>
            </button>
            <button
              className={twMerge(
                "rounded-ts-card transition-al px-4 py-2 text-sm",
                autoLoginEnabled && "bg-neutral-800",
                !autoLoginEnabled && "bg-neutral-800",
              )}
              onClick={toggleAutoLogin("international")}
            >
              <div className="flex items-center gap-2 align-middle">
                <div
                  className={twMerge(
                    "h-[10px] w-[10px] rounded-full",
                    autoLoginEnabled ? "bg-green-500" : "bg-red-500",
                  )}
                />
                {autoLoginEnabled ? "Disable Auto Login" : "Login (Int'l user)"}
              </div>
            </button>
          </>
        )}
      </div>
    </div>
  );
};
