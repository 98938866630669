import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGasPump } from "@fortawesome/pro-solid-svg-icons";

type SizeProp = Parameters<typeof FontAwesomeIcon>[0]["size"];

const NetworkFeeDisclosure = ({ size = "sm" }: { size: SizeProp }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className="flex gap-2 text-sm"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <FontAwesomeIcon
        icon={faGasPump}
        className="opacity-50 hover:opacity-70 dark:text-white"
        size={size}
      />

      <Transition
        as={Fragment}
        show={hover}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute left-0 z-20 mt-6 w-full rounded-lg bg-white p-2 text-xs shadow-lg dark:bg-neutral-700 dark:text-white">
          Meso always charges you the quoted estimated network fee even if it
          has changed at transfer time.
        </div>
      </Transition>
    </div>
  );
};

export default NetworkFeeDisclosure;
