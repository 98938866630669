// eslint-disable-next-line no-restricted-imports
import posthog from "posthog-js";
import { InitializeTelemetryOptions, TelemetryEvents } from ".";
import { MesoIntegrationProperty } from "../types";

/** A meso-specific interface to PostHog. */
export type MesoPosthog = {
  init: (options: InitializeTelemetryOptions["posthog"]) => void;
  /**
   * An identifier for the web property using the current PostHog integration.
   *
   * This value will be injected into every `.capture` call's `properties` object so that we can segment data later on if needed.
   **/
  __mesoProperty: MesoIntegrationProperty | null;

  /**
   * Handle changes to user's performance cookie consent
   * @param optIn whether user is opting into telemetry
   */
  updateConsent: (optIn: boolean) => void;

  /**
   * A Meso-specific wrapper around `posthog.capture` that restricts allowed event names.
   */
  capture: (
    eventName: (typeof TelemetryEvents)[keyof typeof TelemetryEvents],
    properties?: Parameters<(typeof posthog)["capture"]>[1],
  ) => void;
} & Pick<typeof posthog, "identify">;

// We only want to do fallback logging if we are running locally or in preview
const shouldLogFallbackCallsIfPostHogDisabled = (() => {
  if (
    import.meta.env.VITE_IS_TEST ||
    import.meta.env.VITEST_VSCODE ||
    import.meta.env.CI === "true"
  ) {
    return false;
  }

  return import.meta.env.DEV || import.meta.env.VITE_TIGRIS_ENV === "preview";
})();

export const Posthog: MesoPosthog = {
  __mesoProperty: null,

  init({ property }: InitializeTelemetryOptions["posthog"]) {
    this.__mesoProperty = property;

    // If PostHog is not enabled, don't even attempt to stand it up.
    // We are using this in favor of calling `posthogClient?.opt_in_capturing()` or `posthogClient?.opt_out_capturing()`
    if (import.meta.env.VITE_POSTHOG_ENABLED === "true") {
      posthog.init(import.meta.env.VITE_POSTHOG_CLIENT_TOKEN, {
        api_host: import.meta.env.VITE_POSTHOG_API_HOST,
        api_transport: "fetch",
        opt_out_capturing_by_default: true,
        person_profiles: "identified_only",
        debug: import.meta.env.VITE_POSTHOG_DEBUG === "1",
      });
    }
  },

  updateConsent(optIn: boolean) {
    // no-op if opted in is already set to the desired value
    if (!posthog.__loaded || posthog.has_opted_in_capturing() === optIn) return;
    optIn ? posthog.opt_in_capturing() : posthog.opt_out_capturing();
  },

  capture(...args) {
    const eventName = args[0];
    const eventProperties = {
      ...args[1],
      __mesoProperty: this.__mesoProperty,
      __mesoEnvironment: import.meta.env.VITE_TIGRIS_ENV,
    };

    if (posthog.__loaded) {
      return posthog.capture(eventName, eventProperties);
    } else if (shouldLogFallbackCallsIfPostHogDisabled) {
      // When running the app locally or in preview, log the data that would otherwise be sent to PostHog
      // eslint-disable-next-line no-console
      console.log("[PostHog.js fallback]: capturing event", {
        eventName,
        properties: eventProperties,
      });
    }
  },

  identify(mesoUserId: string) {
    if (posthog.__loaded) {
      if (!posthog._isIdentified()) {
        posthog.identify(mesoUserId);
      }
    } else if (shouldLogFallbackCallsIfPostHogDisabled) {
      // When running the app locally or in preview, log the data that would otherwise be sent to PostHog
      // eslint-disable-next-line no-console
      console.log("[PostHog.js fallback]: identifying user", mesoUserId);
    }
  },
};
