import { Card } from "./Card";
import { Title, Text, Button } from "@tigris/mesokit";
import { TransferFragment } from "../generated/sdk";
import { TransferItem } from "./TransferItem";
import { motion } from "framer-motion";
import { resolveTransfers } from "../api";
import { toast } from "sonner";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";

const TOAST_ID = "Transfers";

export const Transfers = () => {
  const [transfers, setTransfers] = useState<TransferFragment[]>();

  useEffect(() => {
    const fetchTransfers = async () => {
      const transfersResult = await resolveTransfers();

      if (transfersResult.isErr()) {
        toast.error(transfersResult.error, { id: TOAST_ID });
        return;
      }

      setTransfers(transfersResult.value);
    };
    fetchTransfers();
  }, []);

  const hasTransfers = transfers && transfers.length > 0;

  return (
    <motion.div
      key="Transfers"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="h-full sm:h-auto"
    >
      <Card
        data-testid="Transfers"
        className={hasTransfers ? "" : "rounded-b-3xl"}
      >
        <Title.Medium className="font-bold">Transfers</Title.Medium>
        {hasTransfers ? (
          <div className="mt-6 -mb-4 flex flex-col gap-2">
            {transfers?.map((transfer) => (
              <TransferItem key={transfer.id} transfer={transfer} />
            ))}
          </div>
        ) : (
          <section className="mt-6 flex flex-col items-center gap-4 rounded-2xl bg-neutral-100 p-6 text-center dark:bg-neutral-800">
            <div>
              <Title.Medium className="font-bold">No Transfers</Title.Medium>
              <Text>
                {
                  "Once you've completed your first transfer, it'll show up here."
                }
              </Text>
            </div>
            <Button
              containerClassName="block"
              className="h-8 rounded-3xl px-3 text-xs font-bold"
              primary={false}
              onClick={() => window.open("https://meso.cash", "_blank")}
            >
              Start Your First Transfer
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className="pl-1 opacity-50 dark:opacity-100"
              />
            </Button>
          </section>
        )}
      </Card>
    </motion.div>
  );
};
