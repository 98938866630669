import { Dialog, DialogPanel, Switch } from "@headlessui/react";
import { faShield, faGauge } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState, useCallback } from "react";
import { Button, cookiePreferences, cookieType, DEFAULT_PREFERENCES } from "..";
import { Result } from "neverthrow";
import { AnimatePresence, motion } from "framer-motion";

const COOKIE_DESCRIPTIONS: Record<cookieType, string> = {
  essential:
    "Required for basic site functionality, such as loading pages and saving your preferences. Without these, the site won’t work properly.",
  performance:
    "Collects anonymized data to measure site performance and usage, helping us improve functionality and navigation.",
};

interface CookieDialogProps {
  initialMode?: "banner" | "preferences";
  initialPreferences?: cookiePreferences;
  property: string;
  onCancel: () => void;
  onSavePreferences: (preferences: string) => Promise<Result<true, string>>;
  onSavePreferencesSuccess: (preferences: cookiePreferences) => void;
}

export const CookieDialog: React.FC<CookieDialogProps> = ({
  initialMode = "banner",
  initialPreferences,
  property,
  onCancel,
  onSavePreferences,
  onSavePreferencesSuccess,
}) => {
  const [mode, setMode] = useState<"banner" | "preferences">(initialMode);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [preferences, setPreferences] = useState<cookiePreferences>(
    initialPreferences ||
      (Object.fromEntries(
        Object.keys(DEFAULT_PREFERENCES).map((key) => [key, true]),
      ) as cookiePreferences),
  );

  const togglePreference = useCallback(
    (key: cookieType) => {
      setPreferences((prev: cookiePreferences) => ({
        ...prev,
        [key]: !prev[key],
      }));
    },
    [setPreferences],
  );

  const savePreferences = useCallback(
    async (perf: cookiePreferences) => {
      if (errorMessage) setErrorMessage(undefined);

      const result = await onSavePreferences(
        JSON.stringify({ property, ...perf }),
      );
      if (!result.isOk()) {
        setErrorMessage(result.error);
        return;
      }
      onSavePreferencesSuccess(perf);
    },
    [errorMessage, onSavePreferences, onSavePreferencesSuccess, property],
  );

  const handleAcceptAll = useCallback(() => {
    const allPreferences = Object.fromEntries(
      Object.keys(DEFAULT_PREFERENCES).map((key) => [key, true]),
    ) as cookiePreferences;
    savePreferences(allPreferences);
  }, [savePreferences]);

  const handleRejectAll = useCallback(() => {
    const onlyEssentialPreferences = Object.fromEntries(
      Object.keys(DEFAULT_PREFERENCES).map((key) => [key, key === "essential"]),
    ) as cookiePreferences;
    savePreferences(onlyEssentialPreferences);
  }, [savePreferences]);

  const handleSavePreferences = useCallback(
    () => savePreferences(preferences),
    [preferences, savePreferences],
  );

  const errorComponent = useMemo(() => {
    return errorMessage ? (
      <div className="text-xs text-red-500">{errorMessage}</div>
    ) : (
      <div className="h-4" />
    );
  }, [errorMessage]);

  return (
    <Dialog open onClose={() => {}}>
      <DialogPanel
        as={motion.div}
        initial={{ y: 48, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.3,
            type: "spring",
            stiffness: 100,
            damping: 20,
            delay: 0.3,
          },
        }}
        exit={{ y: 48, opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 pb-4"
      >
        <motion.div
          className="rounded-ts-card w-ts-card flex flex-col space-y-4 bg-white p-6 text-sm dark:bg-neutral-800 dark:text-white"
          animate={{ height: "auto" }}
          layout
          layoutId="CookieDialog"
        >
          <div className="flex flex-col items-center justify-between gap-2">
            <AnimatePresence mode="wait">
              {mode === "preferences" && (
                <motion.div
                  initial={{ y: 8, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 8, opacity: 0 }}
                  key="CookieDialogPreferences"
                  className="flex w-full flex-col justify-end gap-2 text-sm"
                  data-testid="CookieDialogPreferences"
                >
                  <div className="flex flex-col gap-2">
                    <h2 className="text-base font-semibold">
                      Customize your cookie preferences
                    </h2>
                  </div>
                  <div className="dark:bg-ts-subtle/50 bg-ts-subtle/5 flex flex-col justify-between space-y-6 rounded-2xl p-4">
                    {Object.keys(DEFAULT_PREFERENCES).map((key, i) => (
                      <div key={i} className="flex flex-col gap-2">
                        <div className="flex w-full items-center justify-between">
                          <div className="flex items-center gap-1">
                            {key === "essential" && (
                              <FontAwesomeIcon
                                icon={faShield}
                                className="h-3 w-3 text-neutral-400 dark:text-neutral-500"
                              />
                            )}
                            {key === "performance" && (
                              <FontAwesomeIcon
                                icon={faGauge}
                                className="h-3 w-3 text-neutral-400 dark:text-neutral-500"
                              />
                            )}
                            <span className="font-medium capitalize">
                              {key}
                            </span>
                          </div>
                          <Switch
                            id={key}
                            data-testid={`Switch-${key}`}
                            checked={preferences[key as cookieType]}
                            onChange={() => togglePreference(key as cookieType)}
                            disabled={key === "essential"}
                            className={`${
                              preferences[key as cookieType]
                                ? "bg-primary dark:bg-primary-light"
                                : "bg-gray-300 dark:border-neutral-500"
                            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                              key === "essential" &&
                              "cursor-not-allowed opacity-50"
                            }`}
                          >
                            <span
                              className={`${
                                preferences[key as cookieType]
                                  ? "translate-x-6"
                                  : "translate-x-1"
                              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform dark:bg-neutral-700`}
                            />
                          </Switch>
                        </div>
                        {COOKIE_DESCRIPTIONS[key as cookieType]}
                      </div>
                    ))}
                  </div>
                </motion.div>
              )}

              {mode === "banner" ? (
                <motion.div
                  initial={{ y: 8, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 8, opacity: 0 }}
                  data-testid="CookieDialogBanner"
                  className="flex w-full flex-col justify-end gap-2 text-sm"
                >
                  <div className="flex flex-col gap-2">
                    <h2 className="text-base font-semibold">
                      We care about your privacy
                    </h2>
                    <div className="text-sm tracking-tight">
                      We use cookies to give you the best experience and to help
                      improve Meso. <span className="font-semibold">None</span>{" "}
                      of our cookies are used for advertising.
                    </div>
                  </div>

                  {errorComponent}
                  <Button
                    data-testid="CookieDialog:CustomizeButton"
                    primary={false}
                    onClick={() => setMode("preferences")}
                    className="h-10 font-semibold"
                    static
                  >
                    Customize
                  </Button>
                  <Button
                    data-testid="CookieDialog:RejectAllButton"
                    onClick={handleRejectAll}
                    className="h-10 font-semibold"
                    static
                  >
                    Reject All
                  </Button>
                  <Button
                    data-testid="CookieDialog:AcceptAllButton"
                    onClick={handleAcceptAll}
                    className="h-10 font-semibold"
                    static
                  >
                    Accept All
                  </Button>
                </motion.div>
              ) : (
                <div className="flex w-full flex-col justify-end gap-2">
                  {errorComponent}
                  <Button
                    data-testid="CookieDialog:CancelButton"
                    primary={false}
                    onClick={onCancel}
                    className="h-10 font-semibold"
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid="CookieDialog:SavePreferencesButton"
                    onClick={handleSavePreferences}
                    className="h-10 font-semibold"
                  >
                    Save Preferences
                  </Button>
                </div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </DialogPanel>
    </Dialog>
  );
};
