import * as Factory from "factory.ts";
import { useCookieConsent } from "../../hooks/useCookieConsent";

export const cookieConsentFactory = Factory.Sync.makeFactory<
  ReturnType<typeof useCookieConsent>
>({
  CookieDialog: () => null,
  showCookieDialog: (
    _: Parameters<ReturnType<typeof useCookieConsent>["showCookieDialog"]>[0],
  ) => {},
  preferences: {
    essential: true,
    performance: true,
    property: "transfer-app",
  },
});
