import { faCreditCard } from "@fortawesome/pro-solid-svg-icons";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaymentMethod } from "frames-react";

export const PaymentCardIcon = ({ brand }: { brand?: PaymentMethod }) => {
  let cardIcon = faCreditCard;

  switch (brand) {
    case "Visa":
      cardIcon = faCcVisa;
      break;
    case "Mastercard":
      cardIcon = faCcMastercard;
      break;
    case "American Express":
      cardIcon = faCcAmex;
      break;
    case "Diners Club":
      cardIcon = faCcDinersClub;
      break;
    case "Discover":
      cardIcon = faCcDiscover;
      break;
  }

  return <FontAwesomeIcon icon={cardIcon} />;
};
