import debounce from "lodash.debounce";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LabelledInput } from "./Input/LabelledInput";
import { Text } from "./Text";
import { faLoader } from "@fortawesome/pro-solid-svg-icons";
import {
  EmptyComboboxAddressItem,
  InternationalAddressSuggestion,
  InternationalLookupAddressDetail,
  internationalSmartyLookup,
  isInternationalAddressDetail,
  isInternationalAddressSuggestions,
  isUSAddressSuggestion,
  USAddressSuggestion,
  usLookup,
} from "../utils/smarty";
import { parseUSAddress } from "../utils/parseUSAddress";
import { twMerge } from "tailwind-merge";
import {
  useState,
  useMemo,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
  useContext,
} from "react";
import { useCombobox } from "downshift";
import { formatUSAddressSuggestion } from "../utils/formatUSAddressSuggestion";
import { AutocompleteOption } from "./AddressComboboxOption";
import { CountryCodeAlpha3, NonUSCountryCodeAlpha3 } from "../types";
import { ErrorMessages } from "../utils/errorMessages";
import {
  Country,
  defaultNonUSCountry,
  nonUSCountries,
} from "../utils/countries";
import { Input } from "./Input";
import { CountryCombobox } from "./CountryCombobox";
import { toast } from "sonner";
import { MesoKitContext } from "../MesoKitContext";

export type AddressComboboxProps<
  T extends USAddressSuggestion | InternationalLookupAddressDetail,
> = {
  /** Whether to disable the input element. */
  disabled?: boolean;
  /** Whether to use the international variant of this component. */
  international?: boolean;
  /** For the US variant, this must be set to `USA`. */
  initialCountryCodeAlpha3: CountryCodeAlpha3;
  /**
   * Whether to allow the user to change the country set via `initialCountryCodeAlpha3`.
   *
   * This only applies to the `international` variant. Defaults to `true`.
   * */
  allowCountrySelection?: boolean;
  isValid: boolean;
  onAddressSelected(address?: T, rawInputValue?: string): void;
  onError: (message: string) => void;

  /**
   * For the US variant of this component, we render a `LabelledInput` with a label accessory. This prop allows setting the label text such as "Billing Address" or "Residential Address".
   *
   * If `international` is `true`, this prop is ignored.
   **/
  labelText?: string;
  /**
   * Sets the placeholder text for the `input` element.
   *
   * Defaults to "Your address".
   **/
  placeholder?: string;
  /**
   * Sets the name attribute of the `input` element.
   *
   * Defaults to "address".
   **/
  inputName?: string;
};

const variants = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

/** An address combobox that can be used for US-only addresses or leverage Smarty's international address lookup capabilities. */
export const AddressCombobox = <
  T extends USAddressSuggestion | InternationalLookupAddressDetail,
>({
  disabled = false,
  international = false,
  initialCountryCodeAlpha3,
  onAddressSelected,
  onError = () => {},
  labelText = "",
  isValid,
  inputName = "address",
  placeholder = "Your Address",
  allowCountrySelection = true,
}: AddressComboboxProps<T>) => {
  const { sentry } = useContext(MesoKitContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [suggestionDialogWidth, setSuggestionDialogWidth] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [inputValueString, setInputValueString] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const [suggestions, setSuggestions] = useState<
    USAddressSuggestion[] | InternationalAddressSuggestion[]
  >();
  const [selectedCountry, setSelectedCountry] =
    useState<CountryCodeAlpha3 | null>(initialCountryCodeAlpha3);

  // use ref of full width container diff to calculate width for fixed position dialog
  useLayoutEffect(() => {
    if (containerRef.current) {
      setSuggestionDialogWidth(containerRef.current.offsetWidth);
    }
  }, []);

  const emptyStateItem = useMemo<EmptyComboboxAddressItem>(
    () => ({ __brand: "" }),
    [],
  );
  const emptyStateItems = useMemo<EmptyComboboxAddressItem[]>(
    () => [emptyStateItem],
    [emptyStateItem],
  );

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    setHighlightedIndex,
    getItemProps,
    reset,
  } = useCombobox<
    | USAddressSuggestion
    | InternationalAddressSuggestion
    | EmptyComboboxAddressItem
  >({
    items: suggestions || emptyStateItems,
    itemToString: (item) => {
      if (item && !("__brand" in item)) {
        if (isUSAddressSuggestion(item)) {
          return formatUSAddressSuggestion(item);
        }

        return item.addressText;
      }
      return "";
    },
    onStateChange: async (changes) => {
      const { inputValue, selectedItem } = changes;

      switch (changes.type) {
        case useCombobox.stateChangeTypes.InputChange: {
          setInputValueString(inputValue ?? "");
          setDialogIsOpen(true);
          if (selectedItem && isUSAddressSuggestion(selectedItem)) {
            debouncedLookup(inputValue, selectedItem);
          } else {
            debouncedLookup(inputValue);
          }

          break;
        }
        // Ensure user does not have to hit the down arrow twice when we update the combobox options and the list is refreshed
        case useCombobox.stateChangeTypes.InputKeyDownArrowDown: {
          if (changes.isOpen && changes.highlightedIndex !== undefined) {
            setHighlightedIndex(0);
          }
          break;
        }
        case useCombobox.stateChangeTypes.InputKeyDownEscape: {
          if (!dialogIsOpen) setInputValueString("");
          setSuggestions(undefined);
          setDialogIsOpen(false);
          debouncedLookup.cancel();
          break;
        }

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur: {
          if (selectedItem && "__brand" in selectedItem) {
            onAddressSelected();
          } else if (selectedItem && selectedItem.entries <= 1) {
            // For international addresses, we have to fetch the details of a given address before committing it's selection.
            if (international) {
              setIsLoading(true);
              setDialogIsOpen(false);

              const item = selectedItem as InternationalAddressSuggestion;

              // Fetch the address details and dispatch to the parent component
              const internationalResult = await internationalSmartyLookup({
                search: item.addressText,
                country: selectedCountry as NonUSCountryCodeAlpha3,
                addressId: item.addressId,
                sentry,
              });

              if (
                internationalResult.isOk() &&
                isInternationalAddressDetail(internationalResult.value)
              ) {
                onAddressSelected(internationalResult.value as T);
              } else {
                onError(ErrorMessages.smartyLookup.GENERIC_ERROR);
              }
            } else {
              // selecting Suggestion without additional units
              onAddressSelected(selectedItem as T, inputValue);
            }
          } else if (selectedItem) {
            // selecting Suggestion with additional units
            setInputValueString(inputValue ?? "");

            if (selectedItem && isUSAddressSuggestion(selectedItem)) {
              debouncedLookup(inputValue, selectedItem);
            } else {
              debouncedLookup(selectedItem.addressText, selectedItem.addressId);
            }
          } else {
            if (international) {
              setDialogIsOpen(false);
            } else {
              // blurring with no selected Suggestion, attempt to parse previous
              // input value into address
              const parsed = parseUSAddress(inputValueString);
              if (parsed.isOk()) {
                onAddressSelected(parsed.value as T, inputValueString);
              } else {
                onAddressSelected();
                setDialogIsOpen(false);
              }
            }
          }
          break;
        }
      }
    },
  });

  const handleSmartyLookup = useCallback(() => {
    if (international) {
      return async (searchTerm?: string, addressId?: string) => {
        setIsLoading(true);

        const internationalResult = await internationalSmartyLookup({
          search: searchTerm ?? "",
          country: selectedCountry as NonUSCountryCodeAlpha3,
          addressId,
        });

        if (
          internationalResult.isOk() &&
          isInternationalAddressSuggestions(internationalResult.value) &&
          internationalResult.value.length > 0
        ) {
          setSuggestions(internationalResult.value);
        } else {
          // if no results, automatically highlight the manual address entry item
          setSuggestions(undefined);
          setHighlightedIndex(0);
        }

        setIsLoading(false);
      };
    }

    return async (inputValue?: string, selectedItem?: USAddressSuggestion) => {
      setIsLoading(true);

      let search = inputValue ?? "";
      let selected = "";
      if (selectedItem && selectedItem.entries > 1) {
        // per https://www.smarty.com/docs/cloud/us-autocomplete-pro-api#pro-secondary-expansion
        search = `${selectedItem.streetLine} ${selectedItem.secondary}`.trim();
        selected = `${search} (${selectedItem.entries}) ${selectedItem.city} ${selectedItem.state} ${selectedItem.zipcode}`;
      }
      const usLookupResult = await usLookup(search, selected);

      if (usLookupResult.isOk() && usLookupResult.value.length > 0) {
        setSuggestions(usLookupResult.value);
      } else {
        // if no results, automatically highlight the manual address entry item
        setSuggestions(undefined);
        setHighlightedIndex(0);
      }

      setIsLoading(false);
    };
  }, [international, selectedCountry, setHighlightedIndex]);

  // setup memoized debounce on initial render, ensure in-flight lookup is canceled when unmounted
  const debouncedLookup = useMemo(
    () => debounce(handleSmartyLookup(), 300, { leading: true }),
    [handleSmartyLookup],
  );
  useEffect(() => debouncedLookup.cancel(), [debouncedLookup]);

  useEffect(() => {
    if (selectedCountry === null) {
      toast.error("Please select a country");
    } else {
      toast.dismiss();
    }
  });

  const country = useMemo<Country>(
    () => nonUSCountries.find((c) => c.countryCodeAlpha3 === selectedCountry)!,
    [selectedCountry],
  );

  return (
    <div className="relative" ref={containerRef}>
      <div className="relative">
        {international && (
          <div
            className={twMerge(
              "flex flex-col rounded-[16px] dark:bg-neutral-700",
              "divide-y border dark:divide-neutral-600 dark:border-neutral-600",
            )}
          >
            <CountryCombobox
              countries={nonUSCountries}
              onSelectCountry={(countryCode) => {
                setSelectedCountry(
                  countryCode
                    ? ((nonUSCountries.find(
                        (c) => c.countryCodeAlpha2 === countryCode,
                      )?.countryCodeAlpha3 ??
                        defaultNonUSCountry.countryCodeAlpha3) as NonUSCountryCodeAlpha3)
                    : null,
                );
                setSuggestions([]);
                debouncedLookup.cancel();
                reset();
              }}
              disabled={!allowCountrySelection || dialogIsOpen}
              initialCountry={country ? country.countryCodeAlpha2 : undefined}
              clipBorderBottom
            />
            <Input
              className="rounded-t-none! border-0!"
              disabled={disabled || !selectedCountry}
              isValid
              {...getInputProps({ ref: inputRef })}
              data-testid="international-address-combobox-input"
              name={inputName}
              maxLength={255}
              placeholder={placeholder}
            />
          </div>
        )}
        {!international && (
          <LabelledInput
            labelProps={{
              attributes: getLabelProps(),
              text: labelText,
              accessory: (
                <div
                  className={disabled ? "cursor-default" : "cursor-pointer"}
                  onClick={() => !disabled && onAddressSelected()}
                >
                  <Text className="text-[10px] text-neutral-400">
                    Enter manually
                  </Text>
                </div>
              ),
            }}
            disabled={disabled}
            isValid={isValid}
            {...getInputProps({ ref: inputRef })}
            data-testid={`${inputName ? inputName + ":" : ""}combobox-input`}
            name={inputName}
            maxLength={255}
            placeholder={placeholder}
          />
        )}
      </div>
      <AnimatePresence>
        <motion.div
          className="fixed z-20 mt-1.5 max-h-48 overflow-auto rounded-2xl border bg-white text-sm shadow-2xl outline-hidden dark:border-neutral-800 dark:bg-neutral-700"
          style={{
            width: `${suggestionDialogWidth}px`,
            visibility: dialogIsOpen ? "visible" : "hidden",
          }}
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          data-testid="combobox-list"
          {...getMenuProps()}
        >
          {suggestions && suggestions?.length > 0 ? (
            <ul
              className={twMerge(
                "flex flex-col p-2 transition-opacity duration-200 ease-in-out",
                isLoading && "pointer-events-none cursor-auto opacity-30",
              )}
            >
              {suggestions.map((item, index) => (
                <AutocompleteOption
                  item={item}
                  highlighted={highlightedIndex === index}
                  itemProps={getItemProps({ item, index })}
                  key={
                    isUSAddressSuggestion(item)
                      ? `${index}-${item.streetLine}-${item.secondary}-${item.zipcode}`
                      : `${item.addressId}-${item.addressText}`
                  }
                />
              ))}
            </ul>
          ) : (
            <div className="p-2">
              <div
                className="mb-1 flex cursor-default items-center border-b px-4 py-3 text-black/50 transition dark:border-b-neutral-600 dark:text-white"
                key="combobox-fallback-item"
                data-testid="combobox-fallback-item"
              >
                {isLoading ? (
                  <div className="flex items-center gap-1 text-xs font-bold">
                    <FontAwesomeIcon
                      icon={faLoader}
                      size="sm"
                      className="fa-spin"
                    />
                    Searching
                  </div>
                ) : (
                  <div className="text-xs font-bold">No addresses found</div>
                )}
              </div>
              <div
                className={twMerge(
                  "flex cursor-pointer items-center rounded-xl px-4 py-2 transition dark:bg-neutral-700 dark:text-white",
                  highlightedIndex === 0 &&
                    "bg-neutral-200 dark:bg-neutral-600",
                )}
                key="combobox-manual-entry"
                data-testid="combobox-manual-entry"
                {...getItemProps({ item: emptyStateItem, index: 0 })}
              >
                Enter address manually
              </div>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
