import { Button, Text, Title } from "@tigris/mesokit";
import { useAppContext } from "../hooks/useAppContext";

export const ProfileSettingsPrivacyPreferences = () => {
  const {
    cookieConsent: { showCookieDialog },
  } = useAppContext();

  return (
    <>
      <section data-testid="ProfileSettingsPrivacyPreferences">
        <Title.Medium className="font-bold">Privacy Preferences</Title.Medium>
        <Text>Configure your cookie preferences</Text>
      </section>
      <Button
        className="mt-4 px-4 text-sm font-bold"
        containerClassName="block"
        data-testid="ProfileSettingsPrivacyPreferences:button"
        primary={false}
        onClick={() => showCookieDialog("preferences")}
      >
        Configure
      </Button>
    </>
  );
};
