import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/pro-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { Network } from "../types";

type ExplorerLink = {
  name: string;
  url: string;
};

function explorerLink(
  env: string,
  network: Network,
  networkTransactionId: string,
): ExplorerLink | undefined {
  /*
   * Whether the txn is on a network's Mainnet depends on whether Meso is
   * hitting the stubbed, sandbox, or prod ZeroHash. For stubbed, the txn id is
   * a randomly generated uuid that would not show in the explorer so we will
   * not return a explorer link. For sandbox, we should hit the Sepolia, devnet,
   * Mumbai, Sepolia, cluster explorers for ETH, SOL, MATIC, and OP USDC,
   * respectively. For prod, we should hit the Mainnet explorers.
   *
   * The MESO_ENV determines which version of ZH that gets hit:
   * DEV: sandbox (show Sepolia or devnet explorer)
   * LOCAL: stubbed or sandbox (cannot tell at runtime if stubbed or sandbox so
   *   show Sepolia or devnet explorer for txn which may not exist)
   * LOCAL_PROXY: stubbed or sandbox (cannot tell at runtime if stubbed or
   *   sandbox so show Sepolia or devnet explorer for txn which may not exist)
   * SANDBOX: stubbed (we know txn will not exist so do not show explorer link at all)
   * PROD: prod (show Mainnet explorer link)
   */

  env = env.toUpperCase();
  switch (network) {
    case Network.SOLANA_MAINNET:
      switch (env) {
        case "DEV":
        case "LOCAL":
        case "LOCAL_PROXY":
          return {
            url: `https://solana.fm/tx/${networkTransactionId}?cluster=devnet-solana`,
            name: "SolanaFM",
          };
        case "PRODUCTION":
          return {
            url: `https://solana.fm/tx/${networkTransactionId}`,
            name: "SolanaFM",
          };
        default:
          return;
      }
    case Network.ETHEREUM_MAINNET:
      switch (env) {
        case "DEV":
        case "LOCAL":
        case "LOCAL_PROXY":
          return {
            url: `https://sepolia.etherscan.io/tx/${networkTransactionId}`,
            name: "Etherscan",
          };
        case "PRODUCTION":
          return {
            url: `https://etherscan.io/tx/${networkTransactionId}`,
            name: "Etherscan",
          };
        default:
          return;
      }
    case Network.POLYGON_MAINNET:
      switch (env) {
        case "DEV":
        case "LOCAL":
        case "LOCAL_PROXY":
          return {
            url: `https://mumbai.polygonscan.com/tx/${networkTransactionId}`,
            name: "PolygonScan",
          };
        case "PRODUCTION":
          return {
            url: `https://polygonscan.com/tx/${networkTransactionId}`,
            name: "PolygonScan",
          };
        default:
          return;
      }
    case Network.OP_MAINNET:
      switch (env) {
        case "DEV":
        case "LOCAL":
        case "LOCAL_PROXY":
          return {
            url: `https://sepolia-optimism.etherscan.io/tx/${networkTransactionId}`,
            name: "OP Etherscan",
          };
        case "PRODUCTION":
          return {
            url: `https://optimistic.etherscan.io/tx/${networkTransactionId}`,
            name: "OP Etherscan",
          };
        default:
          return;
      }
    case Network.BASE_MAINNET:
      switch (env) {
        case "DEV":
        case "LOCAL":
        case "LOCAL_PROXY":
          return {
            url: `https://sepolia.basescan.org/tx/${networkTransactionId}`,
            name: "BaseScan",
          };
        case "PRODUCTION":
          return {
            url: `https://basescan.org/tx/${networkTransactionId}`,
            name: "BaseScan",
          };
        default:
          return;
      }
    default:
      return;
  }
}

export const BlockchainExplorerButton = ({
  env,
  network,
  networkTransactionId,
  className,
  iconSize = "sm",
}: {
  env: string;
  network: Network;
  networkTransactionId: string;
  className?: string;
  iconSize?: FontAwesomeIconProps["size"];
}) => {
  const link = explorerLink(env, network, networkTransactionId);
  return link ? (
    <button
      className={twMerge(
        "group w-fit rounded-full border-[1px] border-solid px-4 py-1 drop-shadow-2xl transition-all hover:pr-8 dark:border-neutral-700",
        className,
      )}
      data-testid="ExplorerButton"
      onClick={() => window.open(link.url)}
    >
      <div className="flex items-center justify-center gap-2 text-sm font-semibold">
        View on {link.name}
        <FontAwesomeIcon
          icon={faSquareArrowUpRight}
          size={iconSize}
          className="absolute right-4 -rotate-45 opacity-0 transition-all group-hover:rotate-0 group-hover:opacity-100"
        />
      </div>
    </button>
  ) : null;
};
