import ResendTimer from "./ResendTimer";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { OTPInput, OTPInputRef } from "./OTPInput";

interface AuthCodeProps {
  /**
   * Whether the 2FA input should be rendered as disabled.
   */
  disabled: boolean;
  /**
   * Callback invoked when a full 2FA code is provided.
   */
  onAuthCodeChange: (code: string) => void;
  /**
   * Callback that is invoked when resend button is clicked. Resend component is not
   * rendered if this is not provided.
   */
  onResend?: () => void;
  /**
   * The number of seconds before the code can be resent.
   */
  resendSeconds?: number;
  /**
   * The max number of times the code can be resent.
   */
  resendMax?: number;
  /**
   * ClassName for container component that has the 2FA input and resend button
   * components as children.
   */
  containerClassName?: string;
  /**
   * 2FA input component className.
   */
  authCodeClassName?: string;
  /**
   * Resend button component className.
   */
  resendTimerClassName?: string;
}

export type AuthCodeRef = OTPInputRef;

export const AuthCode = forwardRef<OTPInputRef, AuthCodeProps>(
  (
    {
      disabled,
      onAuthCodeChange,
      onResend,
      resendSeconds,
      resendMax,
      containerClassName,
      authCodeClassName,
      resendTimerClassName,
    },
    ref,
  ) => {
    const [otp, setOtp] = useState("");

    const handleOtpChange = (otp: string) => {
      setOtp(otp);

      if (otp.length === 6) {
        onAuthCodeChange(otp);
      }
    };
    const otpInputRef = useRef<OTPInputRef>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        otpInputRef.current?.focus();
      },
      clear: () => {
        otpInputRef.current?.clear();
      },
    }));

    return (
      <div className={containerClassName}>
        <div className={authCodeClassName}>
          {onResend && (
            <div className={twMerge(resendTimerClassName)}>
              <ResendTimer
                {...{ onResend, resendMax, initialSeconds: resendSeconds }}
              />
            </div>
          )}
          <OTPInput
            value={otp}
            onChange={handleOtpChange}
            containerClassName="grid grid-cols-6 gap-3"
            renderInput={(props) => (
              <input
                {...props}
                disabled={disabled}
                className={twMerge(
                  "h-12 w-full rounded-[16px] border border-neutral-300 text-center font-mono text-xl font-medium ring-offset-1 outline-hidden transition duration-150 focus:ring-2",
                  // Required to preserve compatibility with tailwind v3 where this was implicitly set
                  "bg-white",
                  "ring-primary-light dark:border-transparent dark:bg-neutral-700 dark:text-white dark:ring-offset-neutral-800",
                )}
              />
            )}
            ref={otpInputRef}
          />
          {/* </div> */}
        </div>
      </div>
    );
  },
);
