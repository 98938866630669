import { useContext } from "react";
import { Button } from "../Button";
import { Text } from "../Text";
import { motion } from "framer-motion";
import { MesoKitContext } from "../../MesoKitContext";
import { TelemetryEvents } from "@tigris/common";

export const PaymentMethodTooltip = ({
  setIsAddingCard,
}: {
  setIsAddingCard: () => void;
}) => {
  const { posthog } = useContext(MesoKitContext);

  return (
    <motion.div
      // This is scale in small from transfer-app animation.ts
      initial={{ opacity: 0, scale: 0.95, y: 8, zIndex: 50 }}
      animate={{
        opacity: 1,
        scale: 1,
        y: 0,
      }}
      transition={{ delay: 0.25 }}
      exit={{ opacity: 0, scale: 0.95, y: 8 }}
      data-testid="PaymentMethodTooltip"
      className="absolute top-9 z-50 w-[21rem] rounded-2xl border border-neutral-200 bg-white p-3 text-sm shadow-xl dark:border-neutral-700 dark:bg-neutral-800"
    >
      <div className="flex items-center justify-between">
        <Text className="leading-tight font-medium">
          No payment method connected
        </Text>
        <Button
          className="h-6 rounded-xl px-3 py-2 text-xs font-semibold"
          onClick={() => {
            posthog?.capture(TelemetryEvents.fiatInstrumentConnectTooltipClick);
            setIsAddingCard();
          }}
        >
          Connect
        </Button>
      </div>
      <div className="absolute top-[-1px] left-24 size-1.5 -translate-x-2/3 -translate-y-1/2 -rotate-45 transform border-t border-r border-neutral-200 bg-white dark:border-neutral-700 dark:bg-neutral-800"></div>
    </motion.div>
  );
};
