export const TelemetryEvents = {
  accountSettingsAddDebitCardButtonClick:
    "account_settings:add_debit_card_button_click",
  accountSettingsUpdateDebitCardButtonClick:
    "account_settings:update_debit_card_button_click",
  accountSettingsSetTheme: "account_settings:set_theme",
  accountSettingsLinkWalletButtonClick:
    "account_settings:link_wallet_button_click",

  onboardingPaymentMethodSelect: "payment_method:select",

  onboardingInlineDocsVerificationStart: "idv:start",
  onboardingInlineDocsVerificationComplete: "idv:complete",
  onboardingInlineDocsVerificationComponentRender: "idv:component_render",

  onboardingHandoffFail: "onboarding_handoff_fail",

  onboardingThemePickerClose: "theme_picker:close",
  onboardingThemePickerShow: "theme_picker:show",
  onboardingThemePickerSelectTheme: "theme_picker:select_theme",

  onboardingProhibitedRegion: "prohibited_region",
  onboardingPageView: "onboarding:page_view",

  onboardingPhoneNumberAdd: "onboarding:phone_number_add",
  onboardingPhoneNumberVerify: "onboarding:phone_number_verify",
  onboardingDuplicatePhoneNumber: "onboarding:duplicate_phone_number",

  onboardingDeclareResidency: "onboarding:declare_residency",
  onboardingDeclareResidencyUnsupportedCountry:
    "onboarding:declare_residency:unsupported_country",

  onboardingAdministrativeAreaShortCodeUnknown:
    "onboarding:administrative_area_short_code_unknown",

  onboardingWiserUserScamWarning: "onboarding:wiser_user_scam_warning",
  onboardingApplePayScamWarning: "onboarding:apple_pay_scam_warning",

  onboardingPeselEntrySuccess: "onboarding:pesel_entry:success",
  onboardingPeselEntryFailure: "onboarding:pesel_entry:failure",

  onboardingTaxpayerIdEntrySuccess: "onboarding:taxpayer_id_entry:success",
  onboardingTaxpayerIdEntryFailure: "onboarding:taxpayer_id_entry:failure",

  amountEditorInitialize: "amount_editor:initialize",
  amountEditorUnmount: "amount_editor:unmount",
  amountEditorSupportDocsLinkClick: "amount_editor:support_docs_link_click",
  amountEditorEngage: "amount_editor:engage",

  applePayButtonClick: "apple_pay_button:click",
  applePayCancel: "apple_pay:cancel",
  applePayPaymentAuthorized: "apple_pay_payment:authorized",
  applePayPaymentComplete: "apple_pay_payment:complete",
  applePayTooltipClick: "apple_pay:tooltip_click",

  walletLoginSignMessageRequest: "wallet_login:sign_message_request",
  walletLoginSignMessageReceiveResponse:
    "wallet_login:sign_message_receive_response",
  walletLoginSignMessageFail: "wallet_login:sign_message_fail",

  transferApprove: "transfer:approve",
  transferComplete: "transfer:complete",
  transferDecline: "transfer:decline",
  transferExecute: "transfer:execute",
  transferHistoryDownloaded: "transfer:history_downloaded",

  supportMenuGetHelpClick: "support_menu:get_help_click",
  supportMenuClose: "support_menu:close",
  supportMenuToggle: "support_menu:toggle",

  passkeyRegistrationAttempt: "passkey_registration:attempt",
  passkeyRegistrationFail: "passkey_registration:fail",
  passkeyRegistrationSuccess: "passkey_registration:success",
  passkeyRegistrationSkip: "passkey_registration:skip",

  passkeyLoginAttempt: "passkey_login:attempt",
  passkeyLoginFail: "passkey_login:fail",
  passkeyLoginSuccess: "passkey_login:success",

  userActivationStart: "user_activation:start",
  userActivationComplete: "user_activation:complete",

  ckoTokenizationStart: "cko_tokenization:start",
  ckoTokenizationComplete: "cko_tokenization:complete",
  ckoIframesReady: "cko_iframes:ready",
  ckoIframesLoadError: "cko_iframes:load_error",

  rewardsLandingConnectFail: "rewards_landing:connect_error",
  rewardsLandingPageView: "rewards_landing:page_view",
  rewardsLandingAmountSelect: "rewards_landing:amount_select",

  quoteRequestedAmountExceedsLimit: "requested_amount:exceeds_limit",

  fiatInstrumentSelectorShow: "fiat_instrument_selector:show",
  fiatInstrumentSelectorClick: "fiat_instrument_selector:click",
  fiatInstrumentAddDebitCardClick: "fiat_instrument_add_debit_card:click",
  fiatInstrumentRemoveDebitCardClick: "fiat_instrument_remove_debit_card:click",
  fiatInstrumentRemoveDebitCardAccept:
    "fiat_instrument_remove_debit_card:accept",
  fiatInstrumentRemoveDebitCardReject:
    "fiat_instrument_remove_debit_card:reject",
  fiatInstrumentConnectTooltipClick: "fiat_instrument_connect_tooltip:click",
  fiatInstrumentChangeTooltipShow: "fiat_instrument_change_tooltip:show",
} as const;
