import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons";
import { faCcApplePay } from "@fortawesome/free-brands-svg-icons";
import { twMerge } from "tailwind-merge";

const cardSchemeToIcon = (cardScheme?: string) => {
  return cardScheme ? (
    {
      Visa: (
        <div className="flex h-4 w-6 items-center justify-center rounded-xs bg-white">
          <svg
            width="24"
            height="10"
            viewBox="0 0 24 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-5"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.4444 1.30565C17.0602 1.14928 16.4561 0.978638 15.7036 0.978638C13.7829 0.978638 12.4309 2.03609 12.4197 3.55012C12.4075 4.66993 13.3845 5.29463 14.121 5.66686C14.878 6.04842 15.1324 6.29259 15.1294 6.63376C15.1241 7.15558 14.5251 7.39468 13.9666 7.39468C13.1888 7.39468 12.7756 7.27697 12.1367 6.98609L11.8868 6.86205L11.6152 8.60609C12.0682 8.8231 12.9079 9.01147 13.7795 9.02136C15.8207 9.02136 17.147 7.9768 17.1626 6.36002C17.1696 5.47194 16.6519 4.79869 15.5308 4.24293C14.8519 3.88174 14.4353 3.6424 14.44 3.27672C14.4405 2.95281 14.792 2.60624 15.5529 2.60624C16.1884 2.59565 16.6484 2.74719 17.0068 2.90552L17.1812 2.9947L17.4444 1.30565ZM8.45494 8.90583L9.67027 1.11441H11.6129L10.3977 8.90583H8.45494ZM20.805 3.96573C20.805 3.96573 20.1921 5.69562 20.0311 6.14346H21.6439C21.5671 5.75396 21.1943 3.89428 21.1943 3.89428L21.0632 3.22229C21.0114 3.37651 20.9391 3.58088 20.8841 3.73641L20.884 3.73686L20.8839 3.73699L20.8837 3.73764C20.8348 3.87599 20.7996 3.97537 20.805 3.96573ZM22.4278 1.12236H20.9265C20.4608 1.12236 20.1122 1.26021 19.9082 1.76822L17.0233 8.90861H19.0637C19.0637 8.90861 19.3966 7.94804 19.4721 7.73724C19.5767 7.73724 20.0701 7.73813 20.5932 7.73907L20.5937 7.73907C21.1833 7.74014 21.8104 7.74127 21.9603 7.74127C22.0186 8.01317 22.1969 8.90861 22.1969 8.90861H24L22.4278 1.12236ZM6.82515 1.12097L4.92371 6.43285L4.72004 5.35308C4.71999 5.35288 4.71993 5.35268 4.71988 5.35249C4.71995 5.35268 4.72002 5.35288 4.72009 5.35308L4.03931 1.77306C3.92187 1.27931 3.58054 1.13295 3.15865 1.11569H0.0258888L0 1.27712C0.762138 1.4789 1.44365 1.76986 2.04091 2.13175L3.76849 8.89905L5.82448 8.89778L8.8838 1.12097H6.82515Z"
              className="fill-[#1434CB]"
            />
          </svg>
        </div>
      ),
      Mastercard: (
        <div className="flex h-4 w-6 items-center justify-center rounded-xs bg-white">
          <svg
            width="157"
            height="96"
            viewBox="0 0 157 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-5"
          >
            <path
              d="M99.5487 10.2625H57.5488V85.7375H99.5487V10.2625Z"
              fill="#FF5F00"
            />
            <path
              d="M60.2157 48C60.2091 40.7313 61.8562 33.5562 65.0325 27.0181C68.2087 20.48 72.8308 14.7502 78.5489 10.2625C71.4679 4.69672 62.9638 1.23543 54.0087 0.274263C45.0536 -0.686899 36.0087 0.890817 27.9078 4.82715C19.807 8.76349 12.977 14.8996 8.19868 22.5341C3.42032 30.1686 0.886353 38.9935 0.886353 48C0.886353 57.0066 3.42032 65.8315 8.19868 73.466C12.977 81.1005 19.807 87.2366 27.9078 91.1729C36.0087 95.1093 45.0536 96.687 54.0087 95.7258C62.9638 94.7647 71.4679 91.3034 78.5489 85.7376C72.8309 81.2498 68.2088 75.52 65.0326 68.9819C61.8563 62.4438 60.2091 55.2688 60.2157 48Z"
              fill="#EB001B"
            />
            <path
              d="M156.209 48C156.209 57.0065 153.676 65.8313 148.897 73.4658C144.119 81.1003 137.29 87.2364 129.189 91.1728C121.088 95.1092 112.044 96.687 103.089 95.7258C94.1335 94.7647 85.6296 91.3034 78.5488 85.7376C84.2618 81.2453 88.8803 75.5146 92.056 68.9775C95.2318 62.4405 96.8819 55.2677 96.8819 48C96.8819 40.7324 95.2318 33.5596 92.056 27.0225C88.8803 20.4855 84.2618 14.7548 78.5488 10.2625C85.6296 4.69669 94.1335 1.2354 103.089 0.27425C112.044 -0.6869 121.088 0.890886 129.189 4.82726C137.29 8.76364 144.119 14.8998 148.897 22.5343C153.676 30.1688 156.209 38.9936 156.209 48Z"
              fill="#F79E1B"
            />
          </svg>
        </div>
      ),
      APPLE_PAY: (
        <FontAwesomeIcon
          data-testid="FiatInstrumentIcon"
          size="xl"
          icon={faCcApplePay}
        />
      ),
    }[cardScheme]
  ) : (
    <FontAwesomeIcon data-testid="FiatInstrumentIcon" icon={faCreditCard} />
  );
};

const cardSchemeDisplay = (cardScheme?: string, display?: string) => {
  return cardScheme
    ? {
        Visa: display
          ? `Ending ••${display.substring(display.length - 2)}`
          : "••••",
        Mastercard: display
          ? `Ending ••${display.substring(display.length - 2)}`
          : "••••",
        APPLE_PAY: "Apple Pay",
      }[cardScheme]
    : "Connect Card";
};

export const FiatInstrument = ({
  display,
  cardScheme,
  className = "",
}: {
  display?: string;
  cardScheme?: string;
  className?: string;
}) => {
  className = twMerge(
    "flex flex-auto gap-1 items-center",
    className,
    display ? "opacity-100" : "opacity-50",
  );

  return (
    <div data-testid="FiatInstrument" className={className}>
      {cardSchemeToIcon(cardScheme)}
      {cardSchemeDisplay(cardScheme, display)}
    </div>
  );
};
