import { MAX_PASSWORD_LENGTH } from "@tigris/common";

export const ErrorMessages = {
  GENERIC_ERROR: "Something went wrong. Please try again.",
  INVALID_WALLET_ADDRESS: "Invalid address. Please try a different wallet.",
  AUTHENTICATION_EXPIRED: "Authentication expired.",
  emailVerification: {
    INVALID_URI: "Something went wrong. Please contact support.",
  },
  resetPassword: {
    INVALID_URI: "Something went wrong. Please contact support.",
    UNABLE_TO_RESET_PASSWORD: "Unable to reset password. Please try again.",
    TOO_BIG: `The password you've entered is too long. Your password must be less than ${MAX_PASSWORD_LENGTH} characters.`,
    REUSED: "You may not re-use your current password. Please try again.",
    UNAUTHORIZED: "Unauthorized password reset. Please restart and try again.",
  },
  emailPasswordLogin: {
    UNABLE_TO_LOGIN: "Unable to login. Please try again.",
  },
  changePassword: {
    UNABLE_TO_CHANGE_PASSWORD: "Unable to change password. Please try again.",
    BAD_CURRENT_PASSWORD:
      "The curent password is incorrect. Please re-enter and try again.",
    TOO_BIG: `The password you've entered is too long. Your password must be less than ${MAX_PASSWORD_LENGTH} characters.`,
    REUSED: `You may not re-use your current password. Please try again with another password.`,
  },
  removeWallet: {
    UNABLE_TO_REMOVE_WALLET: "Unable to remove wallet. Please try again.",
  },
  downloadTransfers: {
    UNABLE_TO_DOWNLOAD_TRANSFERS:
      "Unable to download transfers. Please try again.",
  },
  dataConsent: {
    GENERIC_ERROR: "Unable to save preferences. Please try again.",
  },
};
