import { TransferKind } from "../generated/sdk";
import { twMerge } from "tailwind-merge";

export const TransferKindBadge = ({
  kind,
  className,
}: {
  kind: TransferKind;
  className?: string;
}) => {
  const shared = twMerge(
    "h-fit rounded-3xl px-1 py-[2px] text-xs font-semibold flex items-center w-max",
    className,
  );

  if (kind === TransferKind.CASH_IN) {
    return (
      <div className={twMerge(shared, "bg-[#05E493] text-[#1E2025]/50")}>
        Cash In
      </div>
    );
  } else if (kind === TransferKind.CASH_OUT) {
    return (
      <div className={twMerge(shared, "bg-[#4B4F56] text-[#BBBEC4]")}>
        Cash Out
      </div>
    );
  }
};
