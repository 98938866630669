import { twMerge } from "tailwind-merge";
import {
  InternationalAddressSuggestion,
  isUSAddressSuggestion,
  USAddressSuggestion,
} from "../utils/smarty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/pro-regular-svg-icons";
import { useCombobox } from "downshift";
import { Text } from "./Text";
import { motion } from "framer-motion";

const getUSAddressText = (item: USAddressSuggestion) => {
  const lineAddress = [item.streetLine, item.secondary]
    .filter((addressComponent) => addressComponent)
    .join(" ");
  return `${lineAddress}, ${item.city}, ${item.state} ${item.zipcode}`;
};

const OptionItem = ({
  addressText,
  highlighted,
  entries,
}: {
  addressText: string;
  entries: number;
  highlighted: boolean;
}) => (
  <div className="relative flex w-full flex-col justify-between gap-0 p-2">
    <div className="relative z-10">
      <Text className="flex items-center gap-1.5 text-xs">
        <FontAwesomeIcon icon={faMapMarker} />
        <div className="line-clamp-1">{addressText}</div>
      </Text>
      {entries > 1 && (
        <div className="pl-4 text-xs font-medium opacity-50">
          +{entries} addresses
        </div>
      )}
    </div>

    {highlighted && (
      <motion.div
        transition={{ ease: "easeInOut", duration: 0.125 }}
        className="bg-primary-light absolute inset-0 rounded-xl"
        layoutId="__highlightAnimation"
      />
    )}
  </div>
);

export const AutocompleteOption = ({
  highlighted,
  item,
  itemProps,
}: {
  item: USAddressSuggestion | InternationalAddressSuggestion;
  /** Whether this item should be highlighted. */
  highlighted: boolean;
  /** The props generated by the parent component using downshift's `getItemProps` call.
   *
   * https://www.downshift-js.com/use-combobox/#props-used-in-examples
   */
  itemProps: ReturnType<ReturnType<typeof useCombobox>["getItemProps"]>;
}) => {
  return (
    <li
      className={twMerge(
        "flex cursor-pointer items-center rounded-xl transition dark:bg-neutral-700 dark:text-white",
      )}
      {...itemProps}
      data-testid="combobox-list-item"
      data-highlighted={highlighted}
    >
      <OptionItem
        addressText={
          isUSAddressSuggestion(item)
            ? getUSAddressText(item)
            : item.addressText
        }
        entries={item.entries}
        highlighted={highlighted}
      />
    </li>
  );
};
