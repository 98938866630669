import { useCallback, useContext, useMemo, useState } from "react";
import {
  AddPaymentCardForm,
  AddPaymentCardFormProps,
  addPaymentCardToastError,
  countries,
  MesoKitContext,
  Text,
} from "@tigris/mesokit";
import { resolveAddPaymentCard } from "../api";
import {
  AllowedEEAIssuingBankCountryCodes,
  CountryCodeAlpha2,
  CountryCodeAlpha3,
  User,
} from "../types";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type AddDebitCardFormProps = {
  onSuccess: () => void;
  user: User;
};

const TOAST_ID = "AddWalletForm";

export const AddDebitCardForm = ({
  onSuccess,
  user,
}: AddDebitCardFormProps) => {
  const { toast } = useContext(MesoKitContext);
  // Start with `true` because unlike the transfer app, we aren't waiting for an animation to resolve.
  const [formEnabled, setFormEnabled] = useState(true);
  const userResidenceCountryCodeAlpha3 = useMemo(() => {
    if (!user.residentialAddress) return;

    const matchedCountry = countries.find(
      (country) =>
        country.countryCodeAlpha2 === user.residentialAddress?.countryCode,
    );

    return matchedCountry?.countryCodeAlpha3;
  }, [user.residentialAddress]);

  const handleTokenizationSuccess = useCallback<
    AddPaymentCardFormProps["onTokenizationSuccess"]
  >(
    async (token) => {
      setFormEnabled(false);

      const result = await resolveAddPaymentCard({ input: { token } });

      if (result.isOk()) {
        onSuccess();
      } else {
        toast?.error(addPaymentCardToastError(result.error.code), {
          id: TOAST_ID,
        });

        setFormEnabled(true);
      }
    },
    [onSuccess, toast],
  );

  if (!userResidenceCountryCodeAlpha3) {
    return (
      <div className="flex h-full items-center justify-center">
        <motion.div
          key="Toast"
          data-testid="toast"
          className={twMerge(
            // Layout
            "fixed flex w-[20rem] flex-col items-center gap-2 px-8 py-6 text-center",
            // Visual
            "rounded-xl bg-white text-black shadow-lg dark:bg-neutral-900",
          )}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={{
            initial: {
              opacity: 0,
              y: 90,
            },
            animate: {
              opacity: 1,
              y: 0,
            },
          }}
        >
          <div className="text-3xl">
            <FontAwesomeIcon icon={faWarning} className="text-[#e1af4f]" />
          </div>
          <Text className="text-sm font-bold text-neutral-800">
            Something went wrong
          </Text>
          <Text>
            Please try again. If the issue persists, contact{" "}
            <a href="mailto:support@meso.network" className="underline">
              Meso support
            </a>
            .
          </Text>
        </motion.div>
      </div>
    );
  }

  return (
    <AddPaymentCardForm
      initialCountryCodeAlpha3={userResidenceCountryCodeAlpha3}
      international={userResidenceCountryCodeAlpha3 !== CountryCodeAlpha3.USA}
      formEnabled={formEnabled}
      onTokenizationSuccess={handleTokenizationSuccess}
      formId="AddDebitCardForm"
      allowedIssuingBankCountries={
        userResidenceCountryCodeAlpha3 === CountryCodeAlpha3.USA
          ? [CountryCodeAlpha2.US]
          : Object.values(AllowedEEAIssuingBankCountryCodes)
      }
    />
  );
};
