import { CookieDialog } from "../components/CookieDialog";
import {
  useState,
  useEffect,
  useMemo,
  ComponentProps,
  useCallback,
} from "react";
import { Result } from "neverthrow";
import { MesoIntegrationProperty } from "../types";

const COOKIE_KEY = "meso:appConsent";
const COOKIE_MAX_AGE = 31536000; // 1 year in seconds

export type cookieType = "essential" | "performance";
export type cookiePreferences = Record<cookieType, boolean>;
export const DEFAULT_PREFERENCES: cookiePreferences = {
  essential: true,
  performance: false,
};

function getCookieValue(cookieKey: string) {
  try {
    const value = document?.cookie
      .split("; ")
      .find((row) => row.startsWith(`${cookieKey}=`))
      ?.split("=")[1];
    return value ? JSON.parse(decodeURIComponent(value)) : undefined;
  } catch {
    return undefined;
  }
}

export const useCookieConsent = ({
  cookieKey = COOKIE_KEY,
  property,
  onSavePreferences,
}: {
  cookieKey?: string;
  property: MesoIntegrationProperty;
  onSavePreferences: (preferences: string) => Promise<Result<true, string>>;
}) => {
  const [savedPreferencesInitialized, setSavedPreferencesInitialized] =
    useState(false);
  const [savedPreferences, setSavedPreferences] = useState<
    Record<cookieType, boolean> | undefined
  >(getCookieValue(cookieKey));
  const [show, setShow] = useState(!savedPreferences);
  const [initialMode, setInitialMode] =
    useState<ComponentProps<typeof CookieDialog>["initialMode"]>();

  useEffect(() => {
    if (!savedPreferences) return;

    const currentCookie = getCookieValue(cookieKey);
    const shouldUpdateCookie = !(
      typeof currentCookie === "object" &&
      typeof savedPreferences === "object" &&
      Object.keys(currentCookie).length ===
        Object.keys(savedPreferences).length &&
      Object.keys(savedPreferences).every(
        (k) => savedPreferences[k as cookieType] === currentCookie[k],
      )
    );
    if (shouldUpdateCookie) {
      const cookieAttributes = {
        [`${cookieKey}`]: encodeURIComponent(JSON.stringify(savedPreferences)),
        path: "/",
        "max-age": COOKIE_MAX_AGE,
      };

      // Add additional attributes if not in local environment or testing
      if (
        import.meta.env.VITE_TIGRIS_ENV !== "local" &&
        !import.meta.env.VITE_IS_TEST
      ) {
        const domain = window.location.hostname.split(".").slice(-2).join(".");
        Object.assign(cookieAttributes, {
          Secure: "",
          SameSite: "None",
          domain: `.${domain}`,
        });
      }

      // Convert the object to a cookie string
      const cookieString = Object.entries(cookieAttributes)
        .map(([key, value]) => (value === "" ? key : `${key}=${value}`))
        .join("; ");

      document.cookie = cookieString;
    }
  }, [cookieKey, savedPreferences]);

  useEffect(() => {
    const handleReady = () => {
      setSavedPreferences(getCookieValue(cookieKey));
      setSavedPreferencesInitialized(true);
    };

    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      handleReady();
    } else {
      document.addEventListener("DOMContentLoaded", handleReady);
      return () =>
        document.removeEventListener("DOMContentLoaded", handleReady);
    }
  }, [cookieKey]);

  const onCancel = useCallback(() => setShow(false), [setShow]);
  const onSavePreferencesSuccess = useCallback(
    (perferences: Record<string, boolean>) => {
      setSavedPreferences(perferences);
      setShow(false);
    },
    [setSavedPreferences, setShow],
  );

  const cookieDialog = useMemo(
    () => () => {
      return savedPreferencesInitialized && show ? (
        <CookieDialog
          initialMode={initialMode}
          initialPreferences={savedPreferences}
          property={property}
          onCancel={onCancel}
          onSavePreferences={onSavePreferences}
          onSavePreferencesSuccess={onSavePreferencesSuccess}
        />
      ) : null;
    },
    [
      initialMode,
      onCancel,
      onSavePreferences,
      onSavePreferencesSuccess,
      property,
      savedPreferences,
      savedPreferencesInitialized,
      show,
    ],
  );

  const cookieConsent = {
    CookieDialog: cookieDialog,
    showCookieDialog: (
      mode: ComponentProps<typeof CookieDialog>["initialMode"],
    ) => {
      setShow(true);
      setInitialMode(mode);
    },
    preferences: { property, ...(savedPreferences || DEFAULT_PREFERENCES) },
  };

  return cookieConsent;
};
